.game-page {
  width: 100%;
  height: 100dvh;
  background-repeat: no-repeat;
  --nav-height: 50px;

  .game-page-wrapper {
    height: calc(var(--screen-height) - var(--nav-height));
  }

  .game-page-nav {
    height: var(--nav-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--space-lg);
    background: var(--color-dark);
    border-bottom: 1px solid var(--color-grey-8);
    gap: var(--space-lg);
    z-index: 400;

    .icon {
      cursor: pointer;
    }

    .left,
    .right {
      display: flex;
      flex-direction: row;
      gap: var(--gap);
    }

    .right {
      justify-content: flex-end;
    }

    .left,
    .right,
    .center {
      flex: 1;
    }
  }

  .title {
    color: var(--color-grey-1);
    text-align: center;
    font-size: var(--text-xs);
    font-weight: 700;
  }

  @media (max-device-width: 1000px) and (orientation: landscape) {
    display: flex;
    flex-direction: row;
    .game-page-wrapper {
      flex: 1;
      height: 100dvh;
    }
    .game-page-nav {
      flex-direction: column;
      height: auto;
      padding: var(--space-lg) var(--space-md);
      border-bottom: none;
      border-right: 1px solid var(--color-grey-8);

      .left,
      .right {
        flex-direction: column;
      }
      .title {
        writing-mode: vertical-rl;
        text-orientation: upright;
      }
    }
    .action {
      flex-direction: column;
      gap: var(--space-sm);
    }    
    .btn-small {
      margin-left: 0;
      padding: var(--space-md) 0;
      height: auto;
      width: 20px;
      span {
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
    }
  }
}