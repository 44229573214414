.BetslipActive {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: hidden;
  width: 100vw;

  #SportsBookIframe {
    height: calc(100dvh - 59px - 60px) !important;
  }
  #betslip-bubble {
    display: none;
  }
}

.sports-page {
  .top-nav-wrapper {
    margin-bottom: 0;
  }
  .page-content {
    padding: 0;
  }
}

.SportsIframe {
  margin: 0;
  padding: 0;
  border: 0;
  width: 1px;
  min-width: 100%;
  min-height: calc(100dvh - 59px - 60px) !important;
  position: relative;
  z-index: 1;
}

.OverlayActive {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: hidden;
  width: 100vw;
  iframe {
    max-height: calc(100dvh - 59px - 60px) !important;
  }
  #footer {
    display: none;
  }
  #betslip-bubble {
    display: none;
  }
}
