@layer utilities {
  #launcher {
    @apply bottom-24 #{!important};
    @apply md:bottom-4 #{!important};
  }

  .btn {
    @apply relative px-4 lg:px-6 inline-flex justify-center items-center font-bold select-none whitespace-nowrap rounded-3xl transition-all duration-300 py-3 text-sm leading-4 gap-2 uppercase transform;
    @apply active:scale-[0.98] active:duration-75 focus:duration-75;
    @apply focus:ring-0 active:ring-0  focus:outline-none focus:shadow-none active:outline-none active:shadow-none;
    &:disabled {
      @apply opacity-50 pointer-events-none;
    }
    &--white {
      @apply bg-white hover:bg-white text-tertiary-500 hover:text-tertiary;
      @apply dark:bg-tertiary-600 dark:text-tertiary-200 dark:hover:bg-tertiary-600 dark:hover:text-white;
    }
    &--theme {
      @apply bg-[#F5F5F5] hover:bg-tertiary-50 text-tertiary hover:text-tertiary   md:font-bold font-normal;
      @apply dark:bg-tertiary dark:text-[#F5F5F5] dark:hover:bg-tertiary-800 dark:hover:text-white;
    }

    &--primary {
      @apply bg-primary-500 hover:bg-primary-300     text-tertiary-600;
      @apply dark:bg-primary-500 dark:hover:bg-primary-400;
    }
    &--secondary {
      @apply bg-secondary-500 hover:bg-secondary-300 text-tertiary/70;
      @apply dark:bg-tertiary-300 dark:hover:bg-tertiary dark:hover:text-white dark:text-primary-500;
      &--outline {
        @apply ring-1 ring-inset dark:ring-tertiary dark:hover:bg-secondary/20 dark:text-tertiary dark:hover:text-tertiary dark:bg-transparent #{!important};
      }
    }
    &--tertiary {
      @apply bg-tertiary-50 text-tertiary/70 hover:text-tertiary/100;
      @apply dark:bg-white dark:text-primary-500 dark:hover:text-white dark:hover:bg-primary-500;
    }
    &--loading {
      @apply text-transparent #{!important};
    }
    &--lg {
      @apply py-3 px-6 text-lg rounded-[3rem] #{!important};
    }
    &--square {
      @apply w-8 h-8 p-1 inline-flex items-center justify-center rounded  #{!important};
      &.btn--lg {
        @apply w-11 h-11  #{!important};
      }
    }
    &--sm {
      @apply text-xs py-2 px-3 rounded-3xl;
    }

    &--transparent {
      @apply bg-transparent border-transparent hover:bg-white/10;
      transition: all 0.3s;
    }
    &--danger {
      @apply bg-error border-error hover:bg-error/70;
      transition: all 0.3s;
    }
    &--success {
      @apply bg-success-500 border-success-500 hover:bg-success-500/80;
      transition: all 0.3s;
    }
    &--disabled {
      @apply opacity-50 pointer-events-none;
    }
  }
}
@keyframes rotate-gradient {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
}

.live-chat-button,
.video-chat-button {
  img {
    @apply h-10 mx-auto;
  }
}

.mobile-chat-icon {
  img {
    @apply opacity-80;
    @apply dark:filter dark:invert;
  }
}
