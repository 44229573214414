.step-info {
  @apply flex flex-row items-center justify-center;
  .step {
    @apply flex w-10 h-10 rounded-full text-white bg-gradient-to-br from-tertiary-500 to-tertiary-500 font-bold items-center justify-center text-xl border-2 border-tertiary-400 transform transition-all;

    &.active {
      @apply from-primary-500 to-primary-500 scale-110 border-primary-600;
    }
  }

  .spacer {
    height: 2px;
    width: min(50px, 10%);
    background-color: var(--color-border);
  }
}
.registration-phone-input-country {
  .select__control {
    @apply h-full #{!important};
  }
}
.registration-phone-input {
  .PhoneInputCountry {
    @apply hidden #{!important};
  }
  .PhoneInputInput {
    @apply pl-0 border-none #{!important};
  }
}
