.transactions-wrapper {
    
    .transaction-item {
        display: flex;
        &:not(:first-child) {
            margin-top: var(--space-xl);
        }

        .description,
        .date {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid var(--color-neutral-2);
            padding-bottom: var(--space-md);
        }
        &:last-child {
            .description,
            .date {
                border-bottom: unset;
            }
        }
        
        .description {
            font-weight: 600;
            flex: 1;
        }
        .date {
            text-align: right;
            font-size: var(--text-sm);
            gap: var(--space-sm);
        }
    }

    .upload-btn {
        width: 100%;
    }
}