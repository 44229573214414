.casino-promotions {
  &:not(.swiper-initialized) {
    .swiper-wrapper {
      @apply md:grid grid-cols-1 md:grid-cols-3 gap-4 whitespace-nowrap;

      >div {
        @apply md:max-w-none max-w-[90%];
      }
    }
  }

  // dots
  .swiper-pagination {
    @apply relative mt-4;
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    @apply bottom-0 flex gap-3 justify-center items-center;
  }

  .swiper-pagination-bullet {
    @apply m-0 w-3 h-3 rounded-full bg-tertiary opacity-25 dark:bg-tertiary-50 #{!important};
  }

  .swiper-pagination-bullet-active {
    @apply bg-primary-500 w-6 opacity-100 dark:bg-primary-500 #{!important};
  }
}

.custom-pagination {

  // dots
  .swiper-pagination {
    @apply relative mt-4;
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    @apply bottom-0 flex gap-3 justify-center items-center;
  }

  .swiper-pagination-bullet {
    @apply m-0 w-3 h-3 rounded-full bg-tertiary opacity-25 dark:bg-tertiary-50 #{!important};
  }

  .swiper-pagination-bullet-active {
    @apply bg-primary-500 w-6 opacity-100 dark:bg-primary-500 #{!important};
  }
}