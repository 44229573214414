.documents-wrapper {
  padding-top: var(--space-md);
  margin-bottom: var(--space-lg);

  .documents-content {
    display: flex;
    flex-direction: column;
  }

  .documents-tutorial-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .documents-main-column,
  .documents-tutorial-column {
    flex: 1;
  }

  .documents-item {
    display: flex;
    gap: var(--space-md);
    border-bottom: 1px solid var(--color-neutral-2);
    padding-bottom: var(--space-xl);
    align-items: flex-start;

    &:not(:first-child) {
      margin-top: var(--space-xl);
    }

    .verification {
      flex: 1;
    }

    .document-info {
      display: flex;
      flex-direction: column;
    }

    .info-text {
      color: var(--color-neutral-6);
      font-size: var(--text-xs);
      line-height: 1.6;
    }

    .primary-link {
      text-decoration: none;
    }
  }

  .take-photo-btn {
    margin: 0 auto;
    text-align: center;
    a {
      display: inline-block;
      text-decoration: none;
    }
  }

  .documents-tutorial {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    .card-title {
      margin: var(--space-2xl) 0 var(--space-sm);
    }

    .document-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-border);
    }
  }

  .document-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-border);
    padding: var(--space-lg) 0;
    gap: var(--space-lg);
    max-width: var(--max-button-width);
    width: 100%;
  }

  .document-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--max-button-width);
    width: 100%;
  }

  .document-item-name {
    font-size: var(--text-sm);
  }

  .document-upload-btn {
    position: relative;
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    .icon {
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }

  .documents-header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: var(--space-md);
    flex: 1;
    margin-bottom: var(--space-xl);

    .card-title {
      text-align: center;
      flex: 1;
    }

    .documents-go-to-tutorial {
      display: flex;
      align-items: center;
      justify-content: right;
    }

    .promotions-link {
      color: var(--color-accent);
      text-decoration: none;
      text-align: center;
    }
  }

  .documents-info {
    display: flex;
    flex-direction: column;
    gap: var(--space-md);
    align-items: center;
    margin-bottom: var(--space-xl);
  }

  .select-method {
    margin-bottom: var(--space-xl);
    color: var(--text-color);

    .select__control {
      background: var(--docs-dropdown-bg);
      border: 1px solid var(--color-border);
      border: 0;
      box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%),
        0 4px 11px hsl(0deg 0% 0% / 10%);

      .select__value-container {
        padding: 0;
      }
      .select__indicator {
        color: var(--color-primary);
        padding: 0;
        cursor: pointer;
        margin-left: var(--space-md);
      }
    }

    .select__control--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .select__menu {
      background: var(--docs-dropdown-bg);
      margin: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .select__placeholder {
      color: var(--text-color);
    }

    .select__single-value {
      justify-content: space-between;
      font-weight: 700;
      color: var(--text-color);
      margin: 0;
      cursor: pointer;
    }
    .select__option {
      background: var(--docs-dropdown-bg);
      justify-content: space-between;
      color: inherit;
      cursor: pointer;
      font-weight: 700;
      border-bottom: 1px solid var(--color-border);
      padding: var(--space-md) var(--space-4xl) var(--space-md) var(--space-md);
    }
    .select__option--is-selected,
    .select__option:active {
      background: var(--docs-dropdown-bg);
      color: unset;
    }

    .select__menu-list {
      padding: 0;
    }
  }

  .empty-state {
    text-align: center;
  }

  .upload-btn {
    margin-top: var(--space-md);
    width: 100%;
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--space-md);
    margin-top: var(--space-sm);
    padding: var(--space-lg) 0;
    width: 100%;

    .info-row {
      display: flex;
      flex-direction: row;
      gap: var(--space-sm);
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }
    .info-text {
      font-size: var(--text-sm);
      line-height: 1.4;
      color: var(--text-color-faded);
    }
  }

  .info-toggle {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: var(--space-sm);
    color: var(--color-primary);
    font-size: var(--text-xs);
    font-weight: 500;
  }
}
