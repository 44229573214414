.jackpots-group {
  display: flex;
  gap: var(--gap);
  margin-bottom: var(--gap);
  
  .jackpots-group-card {
      flex: 1;
      @include desktop {
      &.empty {
        background-color: var(--color-grey-8);
      }
    }
  }

  .swiper {
    @include mobile {
      &:after {
        position: absolute;
        right: 0;
        top: 0;
        content: '';
        height: 100%;
        width: 20px;
        background-image:
          linear-gradient(
            to left, var(--color-foreground),
            50%, rgba(255, 255, 255, 0)
          );
        z-index: 2;
      }
    }
  }  
}

.jackpots-group-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--space-md);
  border-radius: 16px;
  padding: var(--space-xl) var(--space-md);

  &.empty {
    color: var(--color-neutral-3);
  }

  .jackpot-link {
    .icon {
      display: inline-block;
    }
    a {
      font-size: 16px;
      color: var(--color-neutral-2);
      text-decoration: none;
      font-weight: 600;
    }
  }

  .jackpot-group-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--space-md);
  }

  .jackpot-logo-wrapper {
    position: relative;
    height: 50px;
    width: 200px;
  }

  .jackpot-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-bottom: -34px;
    z-index: 1;

    background-color: var(--color-white);
    border-radius: 50%;
  }
  
  .jackpot-counter-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .counter-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .expand-icon {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 0;
  }

  .jackpot-1 + .expand-icon {
    transform: unset;
    top: unset;
    bottom: 10px;
  }

  .counter-jackpot {
    border: 4px solid var(--color-white);
    border-radius: 12px;
    padding: var(--space-sm) var(--space-lg);
    text-align: center;
    position: relative;

    .counter {
      font-size: var(--text-xl);
      font-weight: 700;
      line-height: 120%;
      &::after {
        content: attr(data-suffix);
        font-size: var(--text-md);
        letter-spacing: normal;
        line-height: 1;
        margin-left: var(--space-sm);
        @media screen and (max-width: 400px) {
          letter-spacing: -1px;
        }
      }
    }
    
    &.jackpot-1 {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 232px;
      height: 76px;

      border: unset;
      border-radius: unset;
      position: relative;

      padding: unset;
      padding-top: var(--space-lg);

      svg {
        position: absolute;
        inset: 0;
      }
      .counter {
        font-size: var(--text-3xl);
        line-height: 125%;
        &::after {
          font-size: var(--text-lg);              
        }
      }
    } 
    &.jackpot-2 {
      min-width: 180px;
    }
    &.jackpot-3 {
      min-width: 150px;
    }
    &.jackpot-4 {
      min-width: 120px;
    }
  }

  .counter-details {
    padding: var(--space-md);
    .row {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--space-md);
      width: 100%;
      text-align: center;
    }
    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      min-width: 120px;
    }
    .title {
      font-size: var(--text-2xs);
      font-weight: 700;
      text-transform: uppercase;
    }
    .amount {
      display: flex;
      align-items: center;
      gap: var(--space-sm);
      margin: var(--space-xs) 0 var(--space-sm);
      font-size: var(--text-xs);
      font-weight: 600;
      span {
        font-size: var(--text-md);
        font-weight: 700;
      }
    }
    .user {
      font-size: var(--text-xs);
      font-weight: 600;
    }
    .date {
      font-size: var(--text-xs);
      font-weight: 600;
    }
    .total-winners {
      display: flex;
      align-items: center;
      gap: var(--space-sm);
      margin-top: var(--space-md);
      font-size: var(--text-xs);
      font-weight: 700;
    }
  }
}

.jackpots-group-card.expandable {

  .counter-wrapper {
    padding: 0 45px;
    @include mobile {
      padding: 0 40px;
      width: 100%;
    }
  }


  @include desktop {
    display: grid;
    align-items: start;
    justify-content: center;
    column-gap: 0;

    grid-template-areas:
      'jackpot-header jackpot-header jackpot-header'
      'jackpot-1 jackpot-1 jackpot-1'
      'jackpot-2 jackpot-3 jackpot-4';

    .jackpot-logo-wrapper,
    .jackpot-icon-wrapper {
      justify-items: center;
    }

    .jackpot-group-header {
      grid-area: jackpot-header;
    }

    .jackpot-1-wrapper {
      grid-area: jackpot-1;
      margin-bottom: var(--space-xl);
    }
    .jackpot-2-wrapper {
      grid-area: jackpot-2;
    }
    .jackpot-3-wrapper {
      grid-area: jackpot-3;
    }
    .jackpot-4-wrapper {
      grid-area: jackpot-4;
    }

    .jackpot-2-wrapper,
    .jackpot-3-wrapper,
    .jackpot-4-wrapper {
      min-width: 270px;
    }

    .counter-jackpot {
      min-width: 156px;
    }

  }
}

.jackpot-group-page {
  .jackpots-group-card {
    margin-bottom: var(--gap);
  }
}