
.responsible-wrapper {
  max-width: 520px;
  margin: 0 auto;
}

.responsible-content {
  max-width: 400px;
  margin: 0 auto;
  @include mobile {
    padding: 0 var(--space-lg);
  }
  
  .responsible-item {
    display: flex;
    flex-direction: column;
    margin-top: var(--space-2xl);
  }

  .limit-title {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);
    line-height: 1.4;
  }

  .limit-toggle {
    margin-left: auto;
  }

  .limit-row {
    display: flex;
    flex-direction: row;
    gap: var(--space-sm);
    align-items: flex-start;
    padding: var(--space-md) 0;
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
  }

  .limit-header {
    margin-top: var(--space-lg);
    color: var(--color-neutral-4);
    font-size: var(--text-xs);
  }

  .limit-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      button {
        width: 100%;
      }
    }
  }

  .limit-item {
    // &:not(.disabled-limit) {
    //   border-top: 1px solid var(--color-neutral-2);
    // }
  }

  .limit-update-info {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);
    margin-top: var(--space-md);
    font-size: var(--text-sm);
    font-weight: 600;
    
    .update-alert {
      display: flex;
      gap: var(--space-sm);
      align-items: center;
      color: var(--color-primary);
      line-height: 1;
    }
    .update-info {
      color: var(--color-grey-1);
      
    }
  }

  .time,
  .limit,
  .actions {
    flex: 1;
  }
  
  .limit {
    flex: 2;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: var(--space-md);
    margin-top: var(--space-md);

    .btn-icon {
      width: 50px;

      .label {
        font-size: var(--text-xs);
        font-weight: 600;
        color: var(--transparent-white);
      }
      
      &:disabled {
        .label {
          color: var(--color-grey-5);
        }
      }
    }
  }
}

.cool-off,
.self-exclusion {
  gap: var(--space-xl);
}

.self-exclusion {
  .section-description {
    text-align: left;
    margin-bottom: var(--space-xl);
  }
  .info-box {
    display: flex;
    padding: var(--space-xl);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--space-lg);
    
    color: var(--color-grey-1);
    font-size: var(--text-sm);
    font-weight: 600;
    text-align: left;
    border: 1px solid var(--color-neutral-6);
    border-radius: var(--radius-lg);
  }
  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-md);
  }
}

.limit-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-xl);

  button {
    width: 100%;
  }
}