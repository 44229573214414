/* Device Breakpoints */
$break-sm: 600px;
$break-md: 992px;
$break-lg: 1400px;

// Mobile devices < 600px
@mixin mobile {
  @media (max-width: #{$break-sm}) {
    @content;
  }
}

// Medium devices  > 600px & < 992px
@mixin tablet {
  @media (min-width: #{$break-sm + 1}) and (max-width: #{$break-md - 1}) {
    @content;
  }
}

// Mobile and Tablet < 992px
@mixin mobile-and-tablet {
  @media (max-width: #{$break-md - 1}) {
    @content;
  }
}


// Large devices > 992px
@mixin desktop {
  @media (min-width: #{$break-md}) {
    @content;
  }
}

// Large desktop devices > 1200px
@mixin desktop-large {
  @media (min-width: #{$break-lg}) {
    @content;
  }
}
