.landing-page.primary {
  background: var(--color-foreground);
  color: var(--color-white);
  min-height: 100vh;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    // gap: var(--space-4xl);
    max-width: 960px;
    margin: 0 auto;

    @include tablet {
      padding: var(--space-2xl);
    }
    @include mobile {
      padding: var(--space-lg);
    }
  }

  .hero-section,
  .main-section,
  .bottom-section {
    margin: var(--space-4xl) 0;
    @include mobile {
      margin: var(--space-lg) 0;
    }
  }

  .btn-large {
    text-transform: unset;
    padding: var(--space-lg) var(--space-3xl);
    height: unset;
  }

  /*  Section 1
  ---------------------------------------- */
  .hero-section {
    display: flex;

    .main-logo {
      @include mobile {
        max-width: 48px;
      }
    }

    .hero-content {
      width: 70%;
    }
    .hero-image {
      width: 30%;
      position: relative;
      min-height: 250px;
    }
    .title {
      font-size: var(--text-5xl);
      font-weight: 700;
      margin-top: var(--space-3xl);
      margin-bottom: var(--space-md);
    }
    .subtitle {
      font-size: var(--text-3xl);
      font-weight: 700;
      margin-bottom: var(--space-3xl);

      &.intermediate {
        margin-bottom: var(--space-md);
      }
    }

    @include tablet {
      flex-direction: column;
      gap: var(--space-3xl);
      .hero-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
      }
      .hero-image {
        width: 100%;
      }
    }

    @include mobile {
      flex-direction: column;
      gap: var(--space-xl);

      .hero-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
      }
      .hero-image {
        width: 100%;
      }
      .title {
        font-size: var(--text-3xl);
      }
      .subtitle {
        font-size: var(--text-2xl);
      }
    }
  }
  /*  Section 2
  ---------------------------------------- */
  .main-section {
    display: flex;
    flex-direction: column;
    gap: var(--space-4xl);

    .steps-wrapper {
      display: flex;
      gap: var(--space-3xl);

      @include tablet {
        gap: var(--space-md);
      }

      @include mobile {
        flex-direction: column;
        gap: var(--space-md);
      }
    }
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      padding: var(--space-xl);
      border-radius: 16px;
      border: 1px solid var(--color-primary);
      text-align: center;

      @include tablet {
        padding: var(--space-xl) var(--space-md);
      }
    }
    .step-number {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 32px;
      height: 32px;
      margin-bottom: var(--space-lg);

      font-size: var(--text-lg);
      font-weight: 700;
      border: 2px solid var(--color-white);
      border-radius: 50%;

      @include mobile {
        font-size: var(--text-md);
      }
    }
    .step-title {
      font-size: var(--text-lg);
      font-weight: 700;
      margin-bottom: var(--space-md);

      @include mobile {
        font-size: var(--text-md);
      }
    }
    .step-description {
      font-size: var(--text-sm);
      font-weight: 600;

      @include mobile {
        font-size: var(--text-xs);
      }
    }
    .content-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--space-md);
    }
  }

  /*  Section 3
  ---------------------------------------- */
  .bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-3xl);

    @include mobile {
      align-items: flex-start;
      padding: var(--space-lg);
      gap: var(--space-lg);
    }

    .section-title {
      font-size: var(--text-2xl);
      font-weight: 700;
      @include mobile {
        font-size: var(--text-lg);
      }
    }

    .highlights {
      display: flex;
      flex-direction: row;
      gap: var(--space-4xl);

      @include mobile {
        flex-direction: column;
        gap: var(--space-lg);
      }

      .col {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        gap: var(--space-lg);
        text-align: center;
        @include mobile {
          gap: var(--space-sm);
          align-items: flex-start;
          text-align: left;
        }
      }
    }
    .highlight-name {
      display: flex;
      font-size: var(--text-lg);
      font-weight: 700;
      gap: var(--space-sm);
      @include mobile {
        font-size: var(--text-md);
      }
    }
    .highlight-description {
      color: var(--color-grey-1);
      font-size: var(--text-md);
      font-weight: 600;
      @include mobile {
        font-size: var(--text-sm);
      }
    }
  }
}

.landing-page.type-a {
  background: var(--color-neutral-darkest);
  color: var(--color-white);

  /* GENERAL
  ---------------------------------------- */

  .layout-header-nav {
    display: none;
  }

  /* TYPOGRAPHY
  ---------------------------------------- */

  font-size: 16px;
  font-weight: 500;

  @include tablet {
    font-size: 12px;
  }

  @include mobile {
    font-size: 10px;
  }

  .heading1,
  .heading2,
  .heading3,
  .heading4,
  .heading5,
  .heading6 {
    font-weight: 900;
    text-transform: uppercase;
    line-height: 0.8;
  }

  .italic {
    font-style: italic;
  }

  .heading1 {
    font-size: 5em;
    letter-spacing: -4px;
  }

  .heading2 {
    font-size: 4em;
    letter-spacing: -2px;
  }

  .heading3 {
    font-size: 3em;
    letter-spacing: -2px;
  }

  .heading4 {
    font-size: 2em;
    letter-spacing: -1px;
  }

  .heading5 {
    font-size: 1.2em;
    line-height: 1;
  }

  .heading6 {
    font-size: 1em;
  }

  .plus {
    font-size: 5em;
    font-weight: 900;
    line-height: 0.8;
  }

  /* FIRST SECTION
  ---------------------------------------- */

  .first-section {
    display: grid;
    position: relative;
    z-index: 1;
    @include mobile {
      height: calc(100vh - 120px);
    }
  }

  .hero-image {
    position: relative;
    width: 65%;
    z-index: 1;

    @include tablet {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include mobile {
      margin-block: auto;
      width: 80%;
      margin-left: -20%;
    }
  }

  .hero-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    width: 50%;
    height: 100%;
    z-index: 2;

    background: linear-gradient(
      90deg,
      rgba(20, 23, 34, 0) 0%,
      rgba(20, 23, 34, 100) 30%,
      rgba(20, 23, 34, 100) 100%
    );

    & > div:nth-child(1) {
      display: flex;
      align-items: flex-end;
      gap: 5px;
      margin-bottom: 10px;
    }

    & > div:nth-child(4) {
      display: flex;
      align-items: center;
      gap: var(--space-lg);

      @include mobile {
        gap: var(--space-md);
      }
    }
  }

  .hero-action {
    margin-top: var(--space-2xl);
    padding: 20px;
    width: 100%;
    max-width: 300px;
    height: auto;
    font-size: 20px;
    text-align: center;

    @include mobile {
      max-width: unset;
      border-radius: unset;
    }
  }

  /* SECOND SECTION
  ---------------------------------------- */

  .second-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--space-5xl);
    position: relative;

    @include mobile {
      display: none;
    }
  }

  .bonus-box {
    display: flex;
    flex-direction: column;
    border: 1px solid #49547b;
    border-radius: 9px;
    padding: 10px 15px 10px 10px;

    & > :nth-child(1) {
      padding-left: 5px;
    }
  }

  /* THIRD SECTION
  ---------------------------------------- */

  .third-section {
    display: grid;
    gap: 40px;
    grid-template-columns: auto 1fr;
    margin: var(--space-5xl) auto var(--space-4xl);

    @include mobile {
      grid-template-columns: 1fr;
      gap: 20px;
      margin: 0;
    }

    .bonus-details-left {
      display: flex;
      flex-direction: column;
      gap: 5px;
      & > :nth-child(1) {
        margin-left: 10px;
      }
      & > :nth-child(2) {
        div {
          @include mobile {
            display: inline-block;
          }
        }
      }
      & > :nth-child(3) {
        margin-top: 5px;
      }
    }

    .bonus-details-right {
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        color: #e2e2e2;
        font-size: 12px;
        align-items: center;

        &:nth-child(2n) {
          background-color: #363f53;
        }
        &:nth-child(2n + 1) {
          background-color: #262c3a;
        }
        &.footer {
          background-color: transparent;
        }
        &.header {
          font-weight: 900;
          color: #fff;
        }
      }
      .col {
        flex: 1;
        padding: 10px;
        text-align: center;
      }
      .total {
        background-color: var(--color-primary);
        color: var(--color-black);
        font-weight: 900;
        padding: 10px 0;
        width: 33%;
        text-align: center;
        margin-left: auto;
        margin-top: 10px;
      }
    }
  }

  /* FORTH SECTION
  ---------------------------------------- */

  .forth-section {
    display: grid;
    gap: 80px;
    grid-template-columns: 1fr 1fr;
    margin: var(--space-5xl) auto var(--space-4xl);

    @include tablet {
      gap: 40px;
    }

    @include mobile {
      grid-template-columns: 1fr;
      gap: 40px;
    }

    .bonus-details-left,
    .bonus-details-right {
      display: grid;
      gap: 20px;
      grid-template-columns: 1.2fr 1fr;
      @include mobile {
        gap: 10px;
        grid-template-columns: 1fr 1.2fr;
      }
    }

    .bonus {
      & > :first-child {
        margin-left: 10px;
      }
    }
    .details {
      font-size: 16px;
      line-height: 1.4;
      padding: 20px 10px;

      @include tablet {
        padding: 0;
      }
      @include mobile {
        font-size: 14px;
        padding: 0;
      }
    }
  }

  /* FIFTH SECTION
  ---------------------------------------- */

  .fifth-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    margin: var(--space-5xl) auto var(--space-4xl);

    .title {
      font-size: 18px;
    }

    .links {
      display: flex;
      flex-direction: column;
      gap: 5px;
      a {
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 14px;
        background: #363f53;
        padding: 5px 20px;
        color: var(--color-white);
        text-decoration: none;
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }

  .call-to-action {
    display: none;
    @include mobile {
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;

      background: linear-gradient(
        180deg,
        rgba(20, 23, 34, 0) 0%,
        rgba(20, 23, 34, 100) 30%,
        rgba(20, 23, 34, 100) 100%
      );

      padding: 0;
      z-index: 20;
    }
  }
}

.landing-page.type-b {
  background: var(--color-neutral-darkest);
  color: var(--color-white);

  /* GENERAL
  ---------------------------------------- */

  .layout-header-nav {
    display: none;
  }

  /* TYPOGRAPHY
  ---------------------------------------- */

  .landing-banner {
    position: relative;
    margin: calc(-1 * var(--gap));
    z-index: 2;

    .hero-text {
      font-size: 90px;
      font-weight: 800;
      line-height: 0.8;
      padding: 40px var(--gap) 0;
      letter-spacing: -2px;

      @include mobile {
        position: relative;
        // transform: translate(50%);
        left: 5%;

        line-height: 1;
        font-size: 42px;
        span:last-child {
          display: block;
          font-size: 42px;
        }
      }
      .text-accent {
        font-style: italic;
        @include mobile {
          font-size: 64px;
          line-height: 0.8;
        }
      }
      .text-grey {
        font-style: italic;
        @include mobile {
          letter-spacing: 0;
        }
      }
      .small {
        font-size: 36px;
        margin-left: 10px;
        @include mobile {
          font-size: 20px;
        }
      }
      .medium {
        font-size: 65px;
        margin-left: 10px;
        @include mobile {
          font-size: 32px;
        }
      }
    }
    .hero-cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--gap);
      color: var(--text-accent);
      font-weight: 800;
      margin-top: 40px;
      margin-bottom: 20px;
      padding: 0 var(--gap);
    }
    .btn-large {
      width: 100%;
      max-width: 300px;
    }
    .hero-footer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0 var(--gap);
      a {
        display: flex;
        flex-direction: row;
        gap: var(--space-sm);
        text-decoration: none;
        color: var(--color-primary);
        font-size: var(--text-md);
        font-weight: 800;
      }
    }
  }

  .landing-content {
    position: relative;
    padding: var(--space-4xl) 0;
    z-index: 1;

    @include mobile {
      padding: var(--space-xl) 0 var(--gap);
    }

    .cards {
      display: flex;
      flex-direction: row;
      gap: var(--space-xl);
      margin-bottom: var(--space-lg);

      @include mobile {
        gap: var(--gap);
        margin-bottom: var(--gap);
      }

      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--space-xl);
        background-color: var(--color-neutral-dark);
        flex: 1;
        font-size: 20px;
        font-weight: 900;
        color: var(--color-accent);
        padding: var(--space-4xl);

        @include mobile {
          padding: 10px 0;
          font-size: 14px;
          background-size: 80%;
        }
      }
    }

    .filled {
      background: var(--color-neutral-dark);
      @include mobile {
        margin: 0 calc(-1 * var(--gap)) calc(-1 * var(--gap));
        width: calc(100% + 2 * var(--gap));
        font-size: 16px;
        color: var(--color-neutral-3);
        line-height: 1.2;
      }
    }
  }
}

.casino-0323 {
  .hero-container {
    .casino {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;
      aspect-ratio: 1400 / 815;
      background: url("../../public/img/lp/hero.png") no-repeat top left;
      background-size: cover;

      &.sweet {
        background: url("../../public/img/lp/hero-sweet.png") no-repeat top left;
        background-size: cover;
      }
      &.big {
        background: url("../../public/img/lp/hero-big.png") no-repeat top left;
        background-size: cover;
      }
      &.kraken {
        background: url("../../public/img/lp/hero-kraken.png") no-repeat top
          left;
        background-size: cover;
      }
      &.shining-crown {
        background: url("../../public/img/lp/hero-shining-crown.png") no-repeat
          top left;
        background-size: cover;
      }
    }

    .sport {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;
      aspect-ratio: 1400 / 815;
      background: url("../../public/img/lp/hero-sports.png") no-repeat top left;
      background-size: cover;

      &.roland-garos {
        background: url("../../public/img/lp/hero-roland-garos.png") no-repeat
          top left;
        background-size: cover;
      }
    }

    .hero-text {
      margin: 0 0 0 800px;
      text-align: right;
      .hero-text-row {
        font-size: 48px;
        font-weight: 800;
        color: #ffffff;
        margin: 0;
        line-height: 0.8em;
        margin-top: 5px;
      }
      .hero-text-row-1 {
        font-size: 105px;
        font-weight: 800;
        color: #28d5ae;
        padding: 150px 0 0 42px;
        margin: 0;
        line-height: 0.8em;
        span {
          font-size: 67px;
          color: #ffffff;
        }
      }
      .hero-text-row-2 {
        padding: 0 0 0 42px;
        margin: 0;
        font-size: 105px;
        font-weight: 800;
        text-transform: uppercase;
        line-height: 0.94em;
      }
      .hero-text-row-3 {
        display: flex;
        flex-direction: row;
        //justify-content: center;
        align-items: center;
        font-size: 88px;
        font-weight: 800;
        line-height: 1em;
        text-transform: uppercase;
        color: #9c32de;
        margin: 0;
        padding: 0;
        span {
          color: #ffffff;
          font-size: 36px;
          font-weight: 800;
          line-height: 0.97em;
          padding-left: 13px;
        }
      }
      .hero-text-plus {
        color: #ffffff;
        font-size: 84px;
        font-weight: 800;
        line-height: 1em;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 5px;

        &::before,
        &::after {
          content: "";
          display: inline-block;
          height: 1px;
          width: 30%;
          background-color: #707070;
        }
      }
    }
  }
  .hero-btn-container-m {
    display: none;
  }
  .hero-btn-container {
    padding: 58px 0 0 844px;
  }
  .hero-btn {
    position: relative;
    display: inline-block;
    height: 80px;
    padding: 0 100px;
    color: #ffffff;
    font-size: 30px;
    font-weight: 800;
    line-height: 80px;
    background-color: #28d5ae;
    background-image: linear-gradient(
      125deg,
      rgba(13, 107, 37, 0.1) 20%,
      rgba(13, 107, 37, 0) 0,
      rgba(13, 107, 37, 0) 80%,
      rgba(13, 107, 37, 0.1) 0
    );
    border-radius: 3px;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.4);
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 150%;
      width: 150%;
      height: 100%;
      background-color: #ffffff;
      opacity: 0.3;
      transform: rotate(45deg);
      transition: all ease 1s;
      animation: shine 6s infinite;
    }
    @keyframes shine {
      0% {
        left: -150%;
      }
      20% {
        left: 150%;
      }
      21% {
        opacity: 0;
      }
      22% {
        left: -150%;
      }
      23% {
        opacity: 0.3;
      }
      43% {
        left: 150%;
      }
      100% {
        left: 150%;
      }
    }
  }
  .hero-text-sub-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    .hero-text-sub {
      min-width: 280px;
      padding: 15px;
      font-size: 45px;
      font-weight: 800;
      line-height: 0.8em;
      color: #ffffff;
      text-transform: uppercase;
      border: 1px solid #49547b;
      border-radius: 9px;
      box-sizing: border-box;
      span {
        color: #28d5ae;
      }
      .sup {
        font-size: 19px;
        font-weight: 800;
        line-height: 1.1em;
      }
      &:first-child {
        margin-right: 180px;
      }
    }
  }

  .oferte-container {
    max-width: 1400px;
    width: 100%;
    margin: 80px auto auto;
    padding: 60px;
    border-top: 1px dashed #28d5ae;
    box-sizing: border-box;

    .hero-text-plus {
      color: #ffffff;
      font-size: 84px;
      font-weight: 800;
      line-height: 1em;
      display: flex;
      align-items: center;
      gap: 5px;

      &::before,
      &::after {
        content: "";
        display: inline-block;
        height: 1px;
        width: 100%;
        background-color: #707070;
      }
    }

    .oferta-row {
      display: flex;
      flex-direction: row;
      padding-bottom: 50px;
    }
    .oferta-row-1 {
      display: flex;
      flex-direction: row;
    }
    .oferta-bonus-sec {
      display: flex;
      flex-direction: row;
    }
    .oferta-bonus-sec-1 {
      display: flex;
      flex-direction: row;
    }
    .big {
      text-transform: uppercase;
      color: #ffffff;
      font-size: 72px;
      font-weight: 800;
      line-height: 0.9em;
      //padding-right: 50px;
      span {
        color: #28d5ae;
      }
      .sup {
        white-space: nowrap;
        font-size: 24px;
        font-weight: 800;
        line-height: 1em;
      }
      &.big-300 {
        min-width: 300px;
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    .table {
      padding-left: 100px;
      flex-grow: 1;
    }
    .oferta-table {
      color: #ffffff;
      flex-grow: 1;
      flex-basis: 0;
      padding: 0 1em;
      background-color: #262c3a;
      font-size: 19px;
      font-weight: 800;
      padding: 10px;
    }

    .oferta-table-odd {
      background-color: #363f53;
    }
    .oferta-table-empty {
      background: transparent;
    }
    .oferta-table-total {
      color: #24262c;
      background-color: #28d5ae;
    }
    .oferta-text-box-1 {
      color: #ffffff;
      font-size: 22px;
      font-weight: 800;
      padding-top: 1.2em;
      padding-right: 100px;
      padding-left: 15px;
      &.last {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .text-center {
      text-align: center;
    }
  }

  .toc {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto 50px auto;
    box-sizing: border-box;
    .toc-row {
      color: #ffffff;
      margin: 0;
      h2 {
        font-size: 28px;
        font-weight: 800;
        text-align: center;
      }
      a {
        color: inherit; /* blue colors for links too */
        text-decoration: inherit; /* no underline */
      }
      h4 {
        position: relative;
        font-size: 18px;
        font-weight: 800;
        padding-left: 2.5em;
        line-height: 1.9em;
        margin-bottom: 15px;
        background-color: #24272c;
        cursor: pointer;
        &:hover {
          background-color: #2e3136;
        }
        &::before {
          position: absolute;
          width: 22px;
          //height: 22px;
          line-height: 1.9em;
          top: 0;
          bottom: 0;
          left: 10px;
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.818' height='12.634' viewBox='0 0 20.818 12.634'%3E%3Cpath id='Icon_awesome-chevron-down' data-name='Icon awesome-chevron-down' d='M10,20.982.733,11.717a1.144,1.144,0,0,1,0-1.618L1.813,9.018a1.144,1.144,0,0,1,1.616,0l7.377,7.342,7.377-7.342a1.144,1.144,0,0,1,1.616,0L20.88,10.1a1.144,1.144,0,0,1,0,1.618l-9.265,9.265A1.144,1.144,0,0,1,10,20.982Z' transform='translate(-0.398 -8.683)' fill='%23fff'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 18px auto;
        }
        &.collapsed {
          &::before {
            transform: rotate(180deg);
          }
        }
      }
      .collapse:not(.show) {
        display: none;
      }
      .toc-text {
        padding-bottom: 1em;
        padding: 0.5em 1em;
        font-size: 15px;
        ul {
          list-style-type: disc;
          padding-left: 2em;
          margin-bottom: 1em;
        }
      }
    }
  }

  @media (max-width: 1399.98px) {
    .hero-container {
      .hero-text {
        margin: 13.42vw 0 0 50vw;
        .hero-text-row {
          font-size: 4vw;
          font-weight: 800;
        }
        .hero-text-row-1 {
          font-size: 7vw;
          font-weight: 800;
          padding: 2.5vw 0 0 3vw;
          span {
            font-size: 7vw;
          }
        }
        .hero-text-row-2 {
          padding: 0 0 0 3vw;
          font-size: 7vw;
          font-weight: 800;
        }
        .hero-text-row-3 {
          font-size: 6.28vw;
          font-weight: 800;
          span {
            font-size: 2.57vw;
            font-weight: 800;
            padding-left: 0.92vw;
          }
        }
        .hero-text-plus {
          font-size: 6vw;
          font-weight: 800;
        }
      }
    }
    .hero-btn-container {
      padding: 4.14vw 0 0 60.28vw;
    }
    .hero-btn {
      height: 5.71vw;
      padding: 0 7.14vw;
      font-size: 2.14vw;
      line-height: 5.71vw;
    }
    .hero-text-sub-container {
      .hero-text-sub {
        min-width: 20vw;
        padding: 1.07vw;
        font-size: 3.21vw;
        font-weight: 800;
        .sup {
          font-size: 1.35vw;
          font-weight: 800;
        }
        &:first-child {
          margin-right: 12.85vw;
        }
      }
    }
    .oferte-container {
      margin: 5.71vw auto auto;
      padding: 4.28vw;
      .hero-text-plus {
        font-size: 6vw;
        font-weight: 800;
      }
      .oferta-row {
        padding-bottom: 3.57vw;
      }
      .big {
        font-size: 5.14vw;
        line-height: 0.9em;
        .sup {
          font-size: 1.71vw;
        }
        &.big-300 {
          min-width: 21.42vw;
        }
      }
      .table {
        padding-left: 7.14vw;
      }
      .oferta-table {
        font-size: 1.35vw;
      }
      .oferta-text-box-1 {
        font-size: 1.57vw;
        font-weight: 800;
        padding-right: 7.14vw;
        padding-left: 1.07vw;
      }
    }
    .toc {
      padding-left: 15px;
      padding-right: 15px;
      .toc-row {
        h2 {
          font-size: 18px;
          font-weight: 800;
        }
        h4 {
          margin-bottom: 0.87vw;
          &::before {
            width: 22px;
            //height: 1.57vw;
            //left: 0.71vw;
          }
        }
      }
    }
  }

  @media (max-width: 575.98px) {
    .hero-container {
      .casino {
        position: relative;
        aspect-ratio: 375 / 411;
        background: url("../../public/img/lp/hero-m.png") no-repeat top left;
        background-size: cover;
        background-position: -20px 0;

        &.sweet {
          background: url("../../public/img/lp/hero-sweet-mobile.png") no-repeat
            top left;
          background-size: cover;
          background-position: -20px 0;
        }

        &.big {
          background: url("../../public/img/lp/hero-big-mobile.png") no-repeat
            top left;
          background-size: cover;
          background-position: -20px 0;
        }

        &.kraken {
          background: url("../../public/img/lp/hero-kraken-mobile.png")
            no-repeat top left;
          background-size: cover;
          background-position: -20px 0;
        }

        &.shining-crown {
          background: url("../../public/img/lp/hero-shining-crown-mobile.png")
            no-repeat top left;
          background-size: cover;
          background-position: -20px 0;
        }
      }
      .sport {
        position: relative;
        aspect-ratio: 375 / 411;
        background: url("../../public/img/lp/hero-m-sports.png") no-repeat top
          left;
        background-size: cover;
        background-position: -20px 0;

        &.roland-garos {
          background: url("../../public/img/lp/hero-roland-garos-mobile.png")
            no-repeat top left;
          background-size: cover;
          background-position: -20px 0;
        }
      }

      .hero-text {
        margin: 0 0 0 45vw;
        .hero-text-row {
          font-size: 5vw;
          font-weight: 800;
        }
        .hero-text-row-1 {
          font-size: 8vw;
          font-weight: 800;
          padding: 27.2vw 0 0 0;
          span {
            font-size: 8.5vw;
            font-weight: 800;
          }
        }
        .hero-text-row-2 {
          padding: 0 0 0 0;
          font-size: 8.5vw;
          font-weight: 800;
        }
        .hero-text-row-3 {
          font-size: 10.13vw;

          font-weight: 800;
          margin-left: -4vw;
          span {
            font-size: 4vw;
            font-weight: 800;
            padding-left: 0.92vw;
          }
        }
        .hero-text-plus {
          font-size: 8.26vw;
          font-weight: 800;
        }
      }
    }
    .hero-btn-container {
      display: none;
    }
    .hero-btn-container-m {
      display: block;
      padding: 3vw 0 0 0;
      width: 92.53vw;
      margin: 0 auto;
      box-sizing: border-box;
      position: relative;
      bottom: 0;
      z-index: 20;
      &.fixed-bottom {
        position: fixed;
        bottom: 0;
        padding: 0;
        width: calc(100% + 2 * var(--gap));
        left: calc(-1 * var(--gap));
        border-bottom: var(--gap) solid var(--color-neutral-darkest);
        a {
          display: block;
        }
      }
    }
    .hero-btn {
      box-sizing: border-box;
      height: 16.53vw;
      padding: 0 7.14vw;
      font-size: 5.6vw;
      font-weight: 800;
      line-height: 16.53vw;
      text-align: center;
      width: 100%;
    }
    .oferte-container {
      padding-top: 10vw;
      margin-top: 10vw;
      .hero-text-plus {
        font-size: 8.26vw;
        font-weight: 800;
      }
      .oferta-row {
        display: flex;
        flex-direction: column;
      }
      .table {
        padding-left: 0;
        flex-grow: 0;
        margin-top: 4vw;
        margin-bottom: 6vw;
      }
      .big {
        font-size: 10.13vw;
        font-weight: 800;
        line-height: 0.9em;
        .big-br {
          display: none;
        }
        .sup {
          font-size: 3.73vw;
          font-weight: 800;
          &.sup-m {
            font-size: 6.93vw;
            font-weight: 800;
          }
        }
        &.big-300 {
          width: 49vw;
          flex-shrink: 0;
        }
      }
      .oferta-table {
        font-size: 2.8vw;
        font-weight: 800;
      }
      .oferta-text-box-1 {
        font-size: 3.46vw;
        font-weight: 800;
        padding-right: 0;
        padding-left: 0;
      }
      .oferta-bonus-sec {
        padding-bottom: 10vw;
      }
    }
    .hero-text-sub-container {
      display: none;
    }
  }
}
//linear-gradient(125deg,rgba(13,107,37,0.1) 20%,rgba(13,107,37,0) 0,rgba(13,107,37,0) 80%,rgba(13,107,37,0.1) 0);
