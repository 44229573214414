.bonuses-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);
  max-width: 400px;
  margin: 0 auto;

  .apply-bonus-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-md);
    margin: 0 0 var(--space-xl) 0;
    width: 100%;
    background: var(--color-grey-8);

    @include mobile {
      margin: var(--gap) 0 var(--space-xl) 0;
    }

    button {
      flex-grow: 1;
      width: 100%;
    }
  }

  .apply-bonus-title {
    font-size: var(--text-lg);
    font-weight: 700;
    // margin-bottom: var(--space-lg);
  }

  .apply-bonus-subtitle {
    font-size: var(--text-sm);
    font-weight: 600;
    color: var(--color-grey-1);
  }

  .feedback {
    text-align: center;
    margin-bottom: var(--space-lg);
  }

  .games {
    .card-items {
      display: flex;
      align-items: center;
      justify-content: center;
      @include desktop {
        margin: 0 calc(-1 * var(--space-md));
      }
      a {
        padding: var(--space-sm);
      }
      a > span {
        border-radius: var(--radius-md);
        overflow: hidden;
      }
    }
  }
}

.bonus-card {
  display: flex;
  flex-direction: column;
  margin: var(--space-2xl) 0;
  padding: 0;

  &:first-child {
    margin-top: var(--space-3xl);
  }

  @include mobile {
    margin: var(--space-md) calc(-1 * var(--space-md));
  }
}

.bonus-type {
  min-width: 30%;
  align-self: center;
  position: relative;
  background: var(--ticket-accent-bg);
  padding: var(--space-sm) var(--space-lg);
  margin-top: calc(-1 * var(--space-3xl));
  margin-bottom: var(--space-xs);
  border-radius: var(--radius-md);

  color: var(--color-white);
  font-weight: 600;
  text-align: center;
}

.bonus-header {
  display: flex;
  justify-content: space-between;
  // background: var(--ticket-row-highlight);
  border-radius: var(--radius-md);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  border-bottom: 1px solid var(--ticket-row-border);
  // background-color: var(--ticket-row-bg);

  .bonus-title {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);
    font-weight: 600;
    text-align: left;
    color: var(--color-primary);
  }

  .bonus-status {
    font-size: var(--text-md);
    font-weight: 600;
    text-align: right;
    border-left: 1px solid var(--ticket-row-border);
    min-width: 200px;
    @include mobile {
      min-width: unset;
    }
  }

  .bonus-title,
  .bonus-status {
    padding: var(--space-md);
  }
}

.bonus-body {
  padding: 0 var(--space-md);
  margin-top: var(--space-md);

  .bonus-progress-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: var(--space-sm);
  }
  .label {
    color: var(--color-white);
    font-weight: 600;
  }
  .value {
    color: var(--color-primary);
    font-weight: 600;
  }
}

.bonus-footer {
  display: flex;
  flex-direction: row;
  padding: var(--space-md);
  align-items: flex-end;
  border-radius: var(--radius-md);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  gap: var(--space-lg);

  @include mobile {
    flex-wrap: wrap;
    justify-content: center;
  }

  .bonus-start {
    flex: 1;
    .date {
      color: var(--color-primary);
    }
    .bonus-terms-n-cond {
      margin-top: var(--space-md);
    }
  }
  .bonus-end {
    flex: 1;
    text-align: right;
    .date {
      color: var(--color-info);
    }
    .bonus-delete-icon {
      margin: var(--space-sm) 0 0 auto;
    }
  }
  .label {
    color: var(--text-color-faded);
  }
  .game-card {
    line-height: 0;
    border-radius: var(--radius-md);
    overflow: hidden;
  }
}
