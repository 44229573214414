.StaticIframe {
  border: none;
  width: 100%;
  height: calc(100vh - 122px);
}

.StaticIframeNoHeader {
  border: none;
  width: 100%;
  height: calc(100vh - 65px);
}

.StaticIframeMinHeight {
  border: none;
  width: 100%;
  height: 600px;
}
