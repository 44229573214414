@layer utilities {
  .jackpot-card {
    @apply relative px-4 pt-8 pb-16 overflow-hidden rounded lg:pt-12 lg:pb-24 bg-no-repeat bg-cover bg-bottom;

    &--lg {
      .jackpot-card-title {
        @apply mb-8 lg:mb-12 lg:text-5xl;
      }
      .jackpot-card-amount {
        @apply text-6xl lg:text-[80px];
      }
      .btn {
        @apply mt-16;
      }
    }
    &--md {
      .jackpot-card-title {
        @apply mb-4 lg:mb-6 lg:text-[32px];
      }
      .jackpot-card-amount {
        @apply lg:text-[80px];
      }
      .btn {
        @apply mt-6 lg:mt-8;
      }
    }
    &--sm {
      .jackpot-card-title {
        @apply mb-4 lg:text-base;
      }
      .jackpot-card-amount {
        @apply lg:text-xl;
      }
      .btn {
        @apply mt-6;
      }
    }
  }
}
