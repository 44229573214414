.login-page {
}

.login {
  .feedback {
    margin-bottom: var(--text-lg);
    margin-left: var(--space-md);
  }
}

.login-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: var(--space-4xl) var(--space-3xl) var(--space-3xl) var(--space-3xl);
  .section-title {
    padding: var(--space-xl);
    text-align: center;
  }
  .section-content {
    .info {
      margin-bottom: var(--space-xl);
    }
  }

  .input-field {
    margin-bottom: var(--space-md);
  }

  .remember-wrapper {
    margin-left: var(--space-md);
  }

  .forgot-link-wrapper {
    padding-left: var(--space-md);
  }

  .section-actions {
    display: flex;
    flex-direction: column;
    margin-top: var(--space-xl);
    gap: var(--space-xl);
    .register {
      align-self: flex-end;
    }
  }

  .showHideIcon.small {
    width: 25px;
    height: 25px;
  }
}

.forgot-password {
  overflow: hidden;

  .forgot-password-recaptcha {
    margin: 0 auto var(--space-xl);
  }

  .section-actions {
    margin-top: unset;
    .btn-large {
      margin-top: unset;
    }
  }
}

.password-confirmation {
  width: 100%;
  margin: 0 auto;
  padding: var(--space-4xl) var(--space-3xl) var(--space-3xl) var(--space-3xl);

  .info {
    font-size: 14px;
    line-height: 1.4;
    margin: var(--space-xl) 0 var(--space-sm);
  }

  .icon {
    margin: 0 auto var(--space-xl);
  }
  .section-actions {
    display: flex;
    flex-direction: column;
    gap: var(--space-md);
  }
  .contact-us-action {
    font-size: 14px;
    display: flex;
    gap: var(--space-sm);
    justify-content: center;
  }
}
