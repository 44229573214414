.account-information-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--space-2xl);
  max-width: 400px;
  margin: 0 auto;

  .account-information-form,
  .change-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .confidentiality {
    border-top: 1px solid var(--color-neutral-2);
    padding-top: var(--space-xl);
    line-height: 1.4;
  }
  .contact-us {
    margin-top: var(--space-2xl);
  }
}

.user-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;

  @include mobile {
    align-items: flex-start;
  }

  .user-account-title {
    font-size: var(--text-lg);
    font-weight: 700;
    margin-bottom: var(--space-lg);
  }

  .user-password-wrapper {
    width: 100%;
    margin-bottom: var(--space-xl);
  }
  .toggle-change-password {
    width: 100%;
  }
  .change-password-form {
    margin: var(--space-xl) 0;
    button {
      width: 100%;
      margin: 0 auto;
    }
  }
}

.account-information-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--space-xl);

  .update-password-btn {
    width: 100%;
    margin: var(--space-md) 0;
  }
  .feedback-wrapper {
    margin: var(--space-md) 0 0;
  }
}

.consent-wrapper {
  margin: 0 auto;
  .consent-header {
    display: flex;
    flex-direction: column;
    gap: var(--space-md);
  }
  .consent-title {
    font-size: var(--text-lg);
    font-weight: 700;
  }
  .consent-info {
    font-size: var(--text-sm);
    font-weight: 600;
    color: var(--color-grey-1);
  }
  .consent-items {
    display: flex;
    flex-direction: column;
    gap: var(--space-2xl);
    margin: var(--space-2xl) auto 0;
    padding-bottom: var(--space-sm);
  }
}
