.slide {
  cursor: grab;
}

// Slider full width
.main-slider {
  position: relative;
  padding-bottom: var(--space-3xl);
  overflow: hidden;

  .swiper {
    border-radius: var(--radius-md);
  }

  .swiper-pagination {
    --slider-dot-width: 32px;
    --slider-dot-height: 8px;
    z-index: unset;

    .swiper-pagination-bullet {
      width: var(--slider-dot-width);
      height: var(--slider-dot-height);
      border-radius: var(--radius-md);
      background: var(--color-grey-5);
    }
    .swiper-pagination-bullet-active {
      background: var(--color-grey-1);
    }
  }

  .slide {
    aspect-ratio: 2.5/1;
    @include desktop {
      aspect-ratio: 2.5/1;
    }
    img {
      border-radius: var(--radius-md);
    }
  }
}

.main-slide {
  position: relative;
  width: 100%;

  .info {
    position: absolute;
    width: calc(100% - 20px);
    left: 10px;
    top: 10px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 2;
  }
}

.simple-slide {
  background: var(--color-neutral-5);
  &::after {
    background: unset;
  }
}

.action-slide {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .info {
    display: flex;
    align-items: flex-start;
    color: var(--color-white);
    justify-content: flex-end;
    padding: 0 20px 20px 20px;

    button {
      margin-top: 20px;
    }

    @include mobile {
      padding: 0 10px 10px 0;
      // button {
      //   font-size: var(--text-xs);
      //   height: auto;
      //   padding: var(--space-sm) var(--space-md);
      // }
    }
  }

  h2 {
    font-size: var(--text-3xl);
    font-weight: 400;
  }

  h2,
  h3 {
    margin: 0;
  }
}

.event-slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--space-sm);
  background: var(--color-neutral-2);

  .slide-image {
    position: relative;
    height: 50px;
  }
  .slide-info {
    display: flex;
    padding: var(--space-md) var(--space-lg);
    .left-info,
    .right-info {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: var(--space-sm);
    }
    .right-info {
      text-align: right;
    }
  }
  .slide-actions {
    display: flex;
    gap: var(--space-sm);
    padding: 0 var(--space-lg) var(--space-lg) var(--space-lg);
    .option {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: var(--space-md) var(--space-lg);
      flex: 1;
      cursor: pointer;

      border-radius: var(--radius-sm);

      background: rgba(0, 0, 0, 0.1);
      color: var(--color-black);

      .value {
        font-weight: bold;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.15);
      }
    }
  }

  .top-info {
    display: flex;
    justify-content: space-between;
    color: var(--color-white);
  }
  .center-content {
    flex: 1;
    color: var(--color-white);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  .bottom-actions {
    display: flex;
    flex-direction: row;
    gap: var(--space-md);
    .option {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: var(--space-sm) var(--space-md);
      flex: 1;
      cursor: pointer;

      border-radius: var(--radius-sm);

      background: var(--color-white);
      background: rgba(255, 255, 255, 0.1);

      color: var(--color-white);
      &:hover {
      }
    }
    .value {
      font-weight: bold;
      color: var(--color-accent);
    }
  }
}
