.react-loading-skeleton {
  --base-color: #bcbcbc;
  --highlight-color: #cacaca;
}
.dark {
  .react-loading-skeleton {
    --base-color: #0f0f0f;
    --highlight-color: #171717;
  }
}
.skeleton-banner {
  line-height: 1;
  display: block;

  .react-loading-skeleton {
    @apply w-full h-full rounded;
  }
}

.skeleton-game-group {
  .react-loading-skeleton {
    aspect-ratio: 1/1;
  }
}
.skeleton-game-group-title {
  .react-loading-skeleton {
    width: 23%;
    margin-top: var(--space-sm);
    margin-right: var(--space-md);

    @include mobile {
      width: 46%;
    }
  }
}
.skeleton-swiper-slide {
  margin-top: var(--space-md);
  width: 70px;
  text-align: center;

  @include mobile {
    width: 90px;
  }

  .skeleton-group-item {
    .react-loading-skeleton {
      width: 52px;
      height: 52px;
    }
  }

  .skeleton-group-label {
    .react-loading-skeleton {
      width: 52px;
      margin-top: var(--space-md);
    }
  }
}
