.profile-page {
  // .main-content {
  //   max-width: 400px;
  //   margin: 0 auto;
  // }

  .profile-page-header {
    display: flex;
    flex-direction: row;
    margin: var(--space-sm) 0 var(--space-lg);
    @include mobile {
      margin: 0 var(--space-2xl);
    }

    .profile-page-data {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
  }

  .profile-side-menu {
    max-width: 400px;
    border: 1px solid var(--color-border);
    border-radius: var(--radius-lg);
    padding: var(--space-lg);

    .menu-group {
      gap: unset;
      border-bottom: unset;
    }
    .menu-group-item {
      padding: var(--space-md);

      &.active {
        background-color: var(--color-grey-8);
        border-radius: var(--radius-md);

        .icon {
          background-color: var(--color-primary);
        }
      }
    }
  }

  .profile-mobile-menu {
    .menu-group {
      gap: unset;
      border-bottom: unset;
    }
    .menu-group-item {
      padding: var(--space-md);

      &.active {
        background-color: var(--color-grey-8);
        border-radius: var(--radius-md);

        .icon {
          background-color: var(--color-primary);
        }
      }
    }
    .spacer {
      margin: var(--space-lg) calc(-1 * var(--space-md));
      width: calc(100% + 2 * var(--space-md));
    }
  }

  .transactions-mobile-menu {
    margin-top: calc(-1 * var(--space-sm));
  }

  .profile-page-subheader {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--space-sm);

    margin: 0 calc(-1 * var(--space-md)) var(--space-xl);
    position: relative;

    cursor: pointer;
    width: calc(100% + 2 * var(--space-md));
    padding: var(--space-md);

    background-color: var(--color-neutral-dark);
    border-top: 1px solid var(--color-neutral-darkest);

    color: var(--color-neutral-3);
    font-size: var(--text-xs);
    font-weight: 600;
    line-height: 1;

    @include tablet {
      margin: 0 calc(-1 * var(--space-lg)) var(--gap);
      width: calc(100% + 2 * var(--space-lg));
    }

    .base {
      font-weight: 700;
    }
  }

  .spacer {
    height: 0;
    border-top: 1px solid var(--color-border);
    margin: var(--space-lg) calc(-1 * var(--space-lg));
    width: calc(100% + 2 * var(--space-lg));
  }

  .logout {
  }

  .section-header {
    display: flex;
    flex-direction: column;
    gap: var(--space-md);
    text-align: center;

    .section-title {
      color: var(--color-white);
      font-size: var(--text-2xl);
      font-weight: 700;
    }
    .section-description {
      color: var(--color-grey-1);
      font-size: var(--text-md);
      font-weight: 600;
    }
  }
}
