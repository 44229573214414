.page-footer {

  /* 0. Footer General Wrappers
  ---------------------------------------- */
  .footer-content {
    display: flex;
    flex-direction: column;
    gap: var(--space-2xl);
    max-width: var(--content-width);
    margin: 0 auto var(--space-2xl);

    .main-col,
    .side-col {
      display: flex;
      flex-direction: column;
      gap: var(--space-3xl);
    }

    .side-col {
      width: 448px;
      align-items: center;
      text-align: center;
      .footer-consumer-links {
        flex-direction: row;
      }
    }

    @include desktop {
      flex-direction: row;
    }
  }

  .footer-section {
    width: 100%;
    max-width: var(--content-width);
    margin: 0 auto;
    padding: 0 var(--space-2xl);
  }

  /* 1. Footer Slider (Recent Winners)
  ---------------------------------------- */
  .footer-slider {
    padding: 0 var(--space-lg);    
  }

  /* 2. Footer Social
  ---------------------------------------- */
  .footer-social {
    display: flex;

    .icons-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--space-2xl);
      padding: var(--space-md) 0;
      width: 100%;

      border-radius: 32px;
      border: 1px solid var(--color-grey-5);
    }

    .icon {
      cursor: pointer;
    }
  }

  /* 3. Footer Links
  ---------------------------------------- */
  .footer-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--space-4xl);

    justify-content: center;
    text-align: left;

    @include mobile {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--space-4xl);
      flex-direction: column;
    }

    a {
      text-decoration: none;
      color: var(--color-grey-1);
      font-weight: 600;

      &:hover {
        color: var(--color-white);
      }
    }

    .category {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: var(--space-sm);
      @include tablet {
        flex: 0 1 20%;
        width: calc(33% - var(--gap));
      }
    }

    .name {
      color: var(--color-grey-1);
      font-size: var(--text-md);
      font-weight: 700;
      margin-bottom: var(--space-md);
    }

    .links {
      display: flex;
      flex-direction: column;
      gap: var(--space-md);
      font-size: var(--text-sm);
      @include desktop {
        align-items: flex-start;
      }
      a {
        color: var(--color-grey-1);
        &:hover {
          color: var(--color-white);
        }
      }
    }
  }

  /* 4. Footer Info
  ---------------------------------------- */
  .footer-info {
    display: flex;
    flex-direction: column;

    color: var(--color-grey-1);
    font-size: var(--text-xs);
    line-height: 16px;
    font-weight: 600;
    p {
      margin: 0;
    }
  }

  .footer-info-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-md);
    margin-bottom: var(--space-xl);
    &.links {
      gap: var(--space-sm);
    }
    a {
      color: var(--color-grey-1);
      text-decoration: unset;
      &:hover {
        color: var(--color-white);
      }
    }
  }

  /* 5. Footer Payments
  ---------------------------------------- */
  .footer-payments {
    display: flex;

    .payments-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: var(--space-lg);
      padding: var(--space-md) 0;
      width: 100%;

      border-radius: 32px;
      border: 1px solid var(--color-grey-5);
    }

    .payments-title {
      color: var(--color-grey-1);
      font-size: var(--text-md);
      font-weight: 700;
    }
    .payments-row {
      display: flex;
      flex-direction: row;
      gap: var(--space-md);
    }
  }

  /* 6. Footer Regulators
  ---------------------------------------- */
  .footer-regulators {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--space-xl);
  }


  /* 7. Footer Consumer Links
  ---------------------------------------- */
  .footer-consumer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    gap: var(--space-md);
  }

  /* 8. Footer Logo
  ---------------------------------------- */
  .footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }  
}