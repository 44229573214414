@import "../abstracts/breakpoints";

.disabled-link {
  pointer-events: none;
}

.display_none {
  display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.pointer {
  cursor: pointer;
}

.cookie_consent {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--space-2xl);

  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--space-2xl) var(--space-lg);
  z-index: 900;

  background: rgba(0, 0, 0, 0.85);
  color: var(--color-white);

  @include mobile {
    flex-direction: column;
  }

  .cookie_consent_btn_wrapper {
    @include mobile {
      width: 100%;
    }
  }

  .cookie_consent_btn {
    padding: var(--space-md);
    border-radius: var(--radius-md);
    background: var(--color-primary);
    color: var(--color-white);
    font-size: var(--text-md);
    font-weight: 700;
    width: 100%;
    min-width: 150px;
    max-width: unset;
  }
}

.full_width_container {
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    z-index: -1;
  }
}
