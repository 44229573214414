.sport-setting {
    .sport-setting-title {
        margin-bottom: var(--space-lg);
    }
    .sport-setting-options {
        display: flex;
        flex-direction: column;
        gap: var(--space-lg);
    }
    .sport-setting-action {
        padding-top: var(--space-3xl);
        button {
            width: 100%;
        }
    }
}