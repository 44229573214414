@-webkit-keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }

  99% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }

  99% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }

  99% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }

  99% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes slidein {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

// PAZURU
@keyframes level-bronze-shadow-animation {
  0% {
    filter: drop-shadow(0px 0px 18px rgba(255, 203, 151, 0.47));
  }
  30% {
    filter: drop-shadow(5px 5px 20px rgba(210, 169, 128, 0.47));
  }
  50% {
    filter: drop-shadow(5px 5px 40px rgba(245, 212, 179, 0.47));
  }
  70% {
    filter: drop-shadow(5px 5px 20px rgba(210, 199, 128, 0.47));
  }
  100% {
    filter: drop-shadow(0px 0px 18px rgba(255, 203, 151, 0.47));
  }
}
@keyframes level-silver-shadow-animation {
  0% {
    filter: drop-shadow(0px 0px 18px rgba(255, 244, 234, 0.47));
  }
  30% {
    filter: drop-shadow(5px 5px 20px rgba(255, 254, 253, 0.47));
  }
  50% {
    filter: drop-shadow(5px 5px 40px rgba(245, 212, 179, 0.47));
  }
  70% {
    filter: drop-shadow(5px 5px 20px rgba(255, 254, 253, 0.47));
  }
  100% {
    filter: drop-shadow(0px 0px 18px rgba(255, 244, 234, 0.47));
  }
}

@keyframes level-gold-shadow-animation {
  0% {
    filter: drop-shadow(0px 0px 18px rgba(255, 248, 189, 0.47));
  }
  30% {
    filter: drop-shadow(5px 5px 20px rgba(254, 245, 157, 0.47));
  }
  50% {
    filter: drop-shadow(5px 5px 40px rgba(252, 241, 143, 0.47));
  }
  70% {
    filter: drop-shadow(5px 5px 20px rgba(254, 245, 157, 0.47));
  }
  100% {
    filter: drop-shadow(0px 0px 18px rgba(255, 248, 189, 0.47));
  }
}
@keyframes bobbing {
  0%,
  100% {
    transform: translateY(0) rotate(6deg);
  }
  50% {
    transform: translateY(-5px) rotate(-1deg);
  }
}

@keyframes text-gradient-flicker {
  33%,
  66%,
  80% {
    filter: drop-shadow(0 0 25px #fb20ff);
  }
}
@keyframes wiggle {
  0% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bg-shadow-animate {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 300% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.bg-shadow-animate {
  background-image: linear-gradient(
    45deg,
    rgba(251, 32, 255, 0.75),
    #ff581110,
    #ff581159,
    #fb20ff,
    #fb20ff,
    #ff5811,
    #fb20ff62,
    rgba(251, 32, 255, 0.75)
  );
  animation: bg-shadow-animate 20s linear infinite;
  background-size: 400%;
}
.animate-wiggle {
  animation: wiggle 2.5s ease-in-out infinite;
}
.level__bronze {
  animation: level-bronze-shadow-animation 4s infinite ease;
}
.level-bronze {
  animation: level-bronze-shadow-animation 4s infinite ease;
}
.level-silver {
  animation: level-silver-shadow-animation 4s infinite ease;
}
.level-seconday {
  animation: level-gold-shadow-animation 4s infinite ease;
}
.bobbing {
  animation: bobbing 3s infinite ease-in-out;
  transform-origin: bottom center;
}
