.cms-page {
  // default common styles
  .cms-page-content {
    margin-bottom: var(--space-4xl);

    h1,
    .cms-page-title {
      font-size: var(--text-3xl);
      text-align: center;
      font-weight: 700;
      margin: var(--space-md) 0 var(--space-md);
      @include mobile {
        text-align: left !important;
      }
    }

    h2,
    .cms-page-subtitle {
      font-size: var(--text-md);
      font-weight: 700;
      margin: var(--space-xl) 0 var(--space-md);
    }

    a {
      color: var(--color-primary);
    }

    p,
    li {
      font-weight: 600;
    }

    strong {
      font-weight: 800;
    }

    img {
      width: 100%;
    }
  }

  .cms-page-actions {
    display: flex;
    flex-direction: column;
    gap: var(--space-lg);
    align-items: center;

    button {
      width: 100%;
    }
  }

  .page-content {
    // default overwrite
    margin-top: calc(-1 * var(--gap));
    margin-bottom: 0;
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }

  .cms-page-header :is(h1, h2, h3, h4, h5, h6):first-child {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .download-wrapper {
    padding: 20px 20px 0 20px;
    text-align: right;
    @include mobile {
      padding: 10px 20px 10px 20px;
      text-align: center;
    }
  }

  .btn-download {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    gap: var(--gap);
    text-align: right;

    color: var(--text-color);
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

    .icon-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .icon {
      display: inline-block;
      margin-top: -3px;
      margin-right: -1px;
    }

    &:hover {
      color: var(--color-primary);
    }
  }
}

/* Small width CMS pages
------------------------------------------ */
.help-page,
.termeni-si-conditii,
.betting-rules,
.politica-de-confidentialitate,
.joaca-responsabil,
.politica-cookie,
.reguli-bonus,
.intrebari-frecvente {
  .page-content > .container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
}

/* TERMS & CONDITIONS
------------------------------------------ */

.termeni-si-conditii {
  .cms-page-content {
    @include mobile {
      padding: var(--gap);
    }
  }
}

/* BETTING RULES
------------------------------------------ */

.betting-rules {
  .cms-page-header {
    h1 {
      margin-top: 0;
    }
  }
  .rules-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);

    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  .rule-item {
    background: var(--ticket-row-border);
    border-radius: var(--radius-md);
    overflow: hidden;
  }
  .rule-title {
    display: flex;
    justify-content: space-between;
    background: var(--ticket-row-border);
    padding: var(--space-lg) var(--space-xl);
    cursor: pointer;
    font-weight: 700;
  }

  .rule-item.active .rule-title,
  .rule-title:hover {
    background: var(--jackpot-details-bg-second);
  }

  .rule-content {
    background: var(--ticket-row-border);
    padding: var(--space-xl);
    line-height: 1.4;
  }
}

/* AGENCIES (SHOPS)
------------------------------------------ */
.shops {
  .input-wrapper {
    margin: var(--space-sm) auto var(--space-xl);
  }

  .shop-locations {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);

    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }

  .shop-location {
    border-radius: var(--radius-md);
    overflow: hidden;
  }

  .shop-locations-header,
  .shop-location-header {
    display: grid;
    grid-template-columns: 20px 1fr 1fr 2fr 0.5fr 20px;
    gap: var(--gap);
    padding: var(--space-lg) var(--space-xl);
    @include mobile {
      display: flex;
      align-items: center;
    }
  }

  .shop-locations-header {
    background: var(--ticket-row-border);
    color: var(--text-color-faded);
    border-radius: var(--radius-md);
    @include mobile {
      display: none;
    }
  }

  .shop-location-header {
    background: var(--ticket-row-border);
    cursor: pointer;
    font-weight: 700;
    .address-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;

      span:last-child {
        font-weight: 400;
      }
    }
  }

  .shop-location.active .shop-location-header,
  .shop-location-header:hover {
    background: var(--jackpot-details-bg-second);
  }

  .shop-location-content {
    display: flex;
    background: var(--ticket-row-border);
    padding: var(--space-xl);
    line-height: 1.4;

    @include mobile {
      flex-direction: column;
    }
  }

  .shop-location-info {
    display: flex;
    flex-direction: column;
    gap: var(--space-md);
    flex: 1;
  }

  .info-row {
    display: flex;
    flex-direction: column;
    line-height: 1;
    gap: var(--space-xs);
    .label {
      font-style: italic;
      color: var(--text-color-faded);
    }
    .content {
      font-weight: 700;
    }
  }

  .shop-tags {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-sm);
    margin-top: var(--space-xs);

    .tag {
      padding: var(--space-xs) var(--space-md);
      border-radius: var(--radius-md);
      color: var(--color-white);
      font-size: 14px;
      line-height: 1.2;
    }
  }
  .shop-location-map {
    flex: 1;
    position: relative;
  }
}

/* DEPOSIT AND WITHDRAW PAGES
------------------------------------------ */

.methods-wrapper,
.casino-game-rules-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  @include mobile {
    gap: var(--space-md);
  }
  .input-wrapper {
    margin: var(--space-sm) auto var(--space-xl);
  }
  .row.header {
    background: var(--ticket-row-border);
    color: var(--text-color-faded);
    border-bottom: unset;
    text-align: center;
    border-top: unset;
  }
  .row {
    display: flex;
    gap: var(--space-xs);
    background: var(--ticket-row-border);
    padding: var(--space-md) 0;
    border-radius: var(--radius-md);
    text-align: center;
  }
  .col {
    display: flex;
    gap: var(--space-sm);
    flex: 1;
  }
  .col:not(.header) {
    align-items: center;
    justify-content: center;
  }
  .col.name {
    flex: 1.5;
  }

  .details {
    flex: 1.5;
    cursor: pointer;
    color: var(--color-primary);
    font-weight: 500;
  }
  .method-img {
    max-width: 80px;
  }
  .details-wrapper {
    background-color: var(--ticket-row-highlight);
    padding: var(--space-md);
    flex-direction: column;
    text-align: left;
  }

  // Mobile UI
  .method-card {
    background-color: var(--color-foreground);
    padding: var(--space-xl);
    box-shadow: var(--card-elevation);
    margin: 0 calc(-1 * var(--gap));

    .row {
      background-color: transparent;
      border-top: 1px solid var(--color-border);
      padding: var(--space-sm) 0;
      text-align: left;
      margin: 0 auto;
      max-width: 250px;
    }
    .col {
      justify-content: flex-start;
      font-size: var(--text-sm);
      &:first-child {
        color: var(--text-color-faded);
        flex: 1;
      }
      &:last-child {
        font-weight: bold;
        flex: 0 0 40%;
      }
    }
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: var(--space-sm);
      padding-bottom: var(--space-lg);
      font-weight: bold;
      .col {
        font-size: var(--text-md);
      }
    }
    .footer {
      font-size: var(--text-sm);
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: var(--space-lg);
    }
    .method-img {
      min-width: 40%;
    }
    .details {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--space-sm);
      padding: var(--space-lg) var(--space-sm) var(--space-sm) var(--space-sm);
      font-size: var(--text-sm);
      border-top: 1px solid var(--color-border);
      margin: 0 auto;
      max-width: 250px;
    }
    .details-wrapper-mobile {
      font-size: var(--text-sm);
      margin-top: var(--space-lg);
    }
  }
}

/* Verification Page
   KYC,
------------------------------------------ */

.verification-page {
  .video-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--space-xl) auto;
    iframe {
      max-width: 100%;
    }
  }
  .steps-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--space-xl);
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: var(--verification-step-bg);
    border-radius: var(--radius-md);
    overflow: hidden;

    padding-top: var(--space-xl);

    width: calc(33% - 2 * var(--space-xl));

    @include tablet {
      width: calc(50% - var(--space-xl));
    }
    @include mobile {
      width: 100%;
    }
  }
  .step-title {
    font-size: var(--text-2xl);
    font-weight: 700;
    padding: 0 var(--space-xl) var(--space-sm);
  }
  .step-description {
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: var(--space-md) var(--space-xl) var(--space-xl);
    line-height: 1.4;
    text-align: center;
  }
  .step-img {
    line-height: 0;
  }
  .text-danger {
    font-weight: 700;
  }
  .primary-link {
    font-weight: 600;
  }
  .locations-link {
    margin-top: var(--space-lg);
  }
}
