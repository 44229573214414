.balance-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--space-md);

  .balance-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-md);
    border: 1px solid var(--color-border);
    padding: var(--space-lg);
  }

  .balance-total-wrapper {
    display: flex;
    align-items: center;
    gap: var(--space-md);
  }

  .balance-total {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: var(--text-sm);
    gap: var(--space-xs);
    span:first-child {
      font-size: var(--text-xl);
    }
  }

  .balance-breakdown-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--space-md);
  }

  .balance-breakdown {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: var(--text-2xs);
    color: var(--color-grey-1);
    span:first-child {
      font-size: var(--text-xs);
    }
  }

  .balance-elements {
    display: flex;
    justify-content: space-evenly;
    gap: var(--space-md);
    background-color: var(--color-highlight);
    text-align: center;

    .element-wrapper {
      display: flex;
      gap: var(--space-sm);
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: var(--text-2xl);
    }
    .element-label {
      font-weight: 700;
      font-size: var(--text-xs);
      text-transform: uppercase;
    }    
  }

  .balance-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-md);

    .btn-large {
      width: 100%;
    }

    @include tablet {
      flex-direction: row;
      .btn-large {
        flex: 1;
      }
    }
  }
}
