[data-theme="dark"] {
  .modal-wrapper {
    .modal-content {
      color: var(--color-neutral-1);
    }
    .modal-actions {
      background: var(--color-grey-9);
    }
  }
}

@media screen and (min-width: 800px) {
  .modal-wrapper {
    .modal-actions {
      flex-direction: row;
      gap: var(--space-xl);
    }
  }
}

// todo
.quick-deposit-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 800;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);

  @media (max-device-width: 1000px) and (orientation: landscape) {
    padding: 0;
  }

  .quick-deposit-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    background: var(--color-white);
    top: 50%;
    max-width: 400px;
    width: 100%;
    overflow-y: visible;
    overflow-x: hidden;
    position: relative;
    transform: translate(-50%, -50%);
    left: 50%;
    margin-bottom: 20px;
    border-radius: var(--radius-lg);

    iframe {
      min-height: 550px;
    }
    @media (max-device-width: 1000px) and (orientation: landscape) {
      top: 0;
      transform: translate(-50%, 0);
      border-radius: 0;

      iframe {
        min-height: 100dvh;
      }
    }
  }
}

.terms-updated-modal {
  .modal-actions {
    flex-direction: column;
  }
  .extra-actions-info {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: var(--space-md);
  }
  .feedback {
    text-align: center;
  }
}

.balance-notification-modal {
  .btn-deposit {
    width: 100%;
  }
  .modal-actions {
    flex-direction: column;
    justify-content: center;
  }
}

.logged-time-modal {
  .modal-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.play-modal {
  z-index: 800;
  .modal {
    .modal-title {
      text-align: left;
      padding: var(--space-xl) var(--space-xl) 0 var(--space-xl);
      .game-name {
        margin-top: 0;
      }
    }
    .modal-content {
      padding: 0 var(--space-xl);
      position: relative;
    }
    .modal-actions {
      background: transparent;
    }

    .game-box {
      width: 100%;
      min-height: 100px;
      aspect-ratio: 14/9;
      position: relative;
      overflow: hidden;
      border-radius: var(--radius-md);
    }
    .game-name {
      font-size: var(--text-md);
      font-weight: 700;
      cursor: pointer;
    }
    .game-company {
      display: flex;
      gap: var(--space-sm);
      align-items: center;
      color: var(--color-neutral-4);
      margin-top: var(--space-sm);
      font-size: var(--text-xs);
      font-weight: 600;
      cursor: pointer;
    }
  }

  @include mobile {
    padding: 0;
    .modal {
      max-width: 100%;
      animation: 0.5s ease-out 0s 1 slidein;
      top: unset;
      transform: unset;
      left: unset;
      bottom: 0;
      position: absolute;
      margin-bottom: 0;
      max-width: 100%;
    }
  }
}

// search modal
.search-modal {
  padding: 0;
  .modal {
    inset: 0;
    transform: unset;
    max-width: unset;
    margin-bottom: unset;
    height: 100dvh;
    justify-content: flex-start;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    gap: var(--space-lg);
    padding: var(--space-lg) var(--space-xl);
    margin-bottom: var(--header-height);

    @include mobile {
      padding: var(--space-lg);
    }

    .game-category {
      margin-bottom: 0;
    }
  }
  .modal-close {
    right: unset;
    left: 20px;
    top: 30px;
  }
  .empty-state-message {
    color: var(--color-grey-1);
    font-size: var(--text-lg);
  }

  .search-input-wrapper {
    display: flex;
    align-items: center;
    gap: var(--space-md);
    width: 100%;

    .close-icon-wrapper {
      border-radius: 50%;
      background-color: var(--color-grey-8);
      flex-shrink: 0;
      padding: 8px;
    }
  }

  .search-input {
    display: flex;
    align-items: center;
    gap: var(--space-lg);
    flex: 1;

    input {
      background: var(--color-white);
      border: 2px solid var(--color-grey-9);
      border-radius: 35px;
      height: 45px;
      width: 100%;
      outline: none;
      color: var(--color-dark);

      &::placeholder {
        color: var(--color-dark);
      }
    }
  }
  .search-list {
    text-align: left;
    list-style: none;
    a {
      display: block;
      width: 100%;
      padding: var(--space-sm) 0;
      color: var(--color-white);
      text-decoration: none;
      cursor: pointer;
    }
  }

  .load-more {
    display: block;
    max-width: 50%;
    width: 100%;
    margin: 0 auto;
  }
}
