.game-of-the-week {
    // .widget-header {
    //   display: flex;
    //   align-items: center;
    //   font-weight: bold;
    //   padding: var(--space-sm) 0 var(--space-md);
    //   gap: var(--space-md);
    // }

    // .widget-title {
    //   font-size: var(--text-md);
    //   font-weight: 900;
    //   text-transform: uppercase;
    //   line-height: 1;
    // }

    .game-banner {
      position: relative;
      aspect-ratio: 5/1;
      border-radius: var(--radius-md);
      overflow: hidden;
      @include mobile {
          aspect-ratio: 5/2;
      }
      .game-hover {
        aspect-ratio: 5/1;
      }
      img {
        transition: transform 0.4s ease;
      }
    }


    
    &.hovered {
      & > .game-banner img {
        transform: scale(1.05);
      }
    }

}