.promotions-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--space-md);
  @include tablet {
    grid-template-columns: 1fr 1fr;
  }
  @include mobile {
    grid-template-columns: 1fr;
  }
  @include desktop {
    gap: var(--space-3xl);
  }
}

.swiper-btn {
  width: 100%;
}

.promotion-card {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  aspect-ratio: 2.5/1;
  cursor: pointer;
  padding: var(--space-md);

  .promotion-name {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    flex: 1;
    color: var(--color-neutral-4);
  }
  .action {
    display: flex;
    justify-content: flex-start;
    z-index: 9;
    position: relative;
  }
}

.promotion-hero {
  width: 100%;
  position: relative;
  aspect-ratio: 2.5/1;
  overflow: hidden;
  margin-bottom: 0;
  border-radius: var(--radius-md);
}

.promotion-header {
  text-align: center;
  .title {
    margin-bottom: var(--space-md);
    font-size: var(--text-2xl);
    font-weight: 600;
    letter-spacing: -1px;
  }
}

.promotion-cards {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  justify-content: center;
  margin: var(--space-xl) 0;

  @include mobile {
    flex-direction: column;
  }

  .promotion-bonus-card {
    display: flex;
    flex-direction: column;
    gap: var(--space-md);
    width: calc(33% - var(--space-md));
    background-color: var(--color-grey-8);
    padding: var(--space-2xl);

    @include tablet {
      width: calc(50% - var(--space-md));
    }

    @include mobile {
      width: 100%;
    }
    .promotion-bonus-header {
      gap: var(--space-sm);
    }
    .promotion-bonus-title {
      margin: 0;
    }
    .promotion-bonus-description {
      font-weight: 600;
    }
    .input-bonus-wrapper {
      display: flex;
      flex-direction: column;
      gap: var(--space-sm);
      .label {
        color: var(--color-grey-1);
        font-weight: 600;
        font-size: var(--text-sm);
      }
      input,
      input:disabled {
        color: var(--color-white);
        font-size: var(--text-md);
        font-weight: 600;
      }
    }
    .promotion-bonus-label {
      font-size: var(--text-sm);
      font-weight: 500;
    }
    .deposit-btn {
      max-width: 100%;
    }
    .copy-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100px;
      @include mobile {
        min-width: 80px;
      }
      &.copied {
        background: var(--color-primary);
      }
    }
  }
}

.promotion-terms {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--space-xl) 0;

  .terms-toggle {
    cursor: pointer;
    display: flex;
    gap: var(--space-md);
    justify-content: space-between;
    justify-content: center;
    align-items: center;

    span {
      font-size: var(--text-sm);
      font-weight: 600;
    }
  }

  .terms-content {
    width: 100%;

    border-top: 1px solid var(--border-color);
    font-size: var(--text-xs);
    line-height: 1.6;

    margin-top: var(--space-lg);
    padding-top: var(--space-lg);

    padding-left: var(--space-xl);
    padding-right: var(--space-xl);

    @include mobile {
      padding-left: var(--space-md);
      padding-right: var(--space-md);
    }

    ul {
      padding-left: var(--space-lg);
      margin-bottom: var(--space-2xl);
    }

    li {
      margin-bottom: var(--space-sm);
    }

    p {
      margin: var(--space-sm) 0;
    }
  }
}

.header-tabs.promotions {
  @include mobile {
    .btn-large {
      padding: 0;
    }
  }
}
