.user-info-card {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--space-md);
  padding: var(--space-md) var(--space-xl);
  margin-bottom: var(--space-xl);
  border: 1px solid var(--color-border);

  .user-details {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--space-xs);

    font-weight: 600;
    font-size: var(--text-sm);
    color: var(--color-grey-1);
  }
}
