#theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;

  cursor: pointer;
  background: 0;
  border: 0;
  position: relative;
  isolation: isolate;
}