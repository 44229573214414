.landing {
  // common card action button
  .card-action {
    width: 100%;
    max-width: 125px;
    z-index: 1;
  }
}

.home-cards {
  display: grid;
  gap: var(--gap);
  grid-template-columns: 1fr;
  grid-template-areas:
    'bet-main'
    'jackpot'
    'bet-live';

  margin-bottom: var(--gap);

  @include desktop {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'bet-main bet-live'
      'jackpot jackpot';
  }

  .jackpot-wrapper {
    grid-area: jackpot;
  }

  .bet-cards {
    &.main {
      grid-area: bet-main;
    }
    &.live {
      grid-area: bet-live;
    }
  }
}

// sports and live cards
.bet-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap);

  .card-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
    height: 117px;
    padding: var(--space-md);
    background: var(--gradient-main);
    border-radius: var(--radius-md);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background: linear-gradient(
        180deg,
        var(--color-secondary) 5%,
        var(--color-primary) 100%
      );
      &::before {
        border: 1px solid var(--color-accent);
        z-index: 2;
      }
    }

    @include mobile {
      padding: var(--space-xl);
      aspect-ratio: 1/1;
      height: unset;

      .card-content {
        flex-direction: column;
      }

      &.sports-card {
        flex: 1.2;
      }
    }

    &::before {
      content: '';
      position: absolute;
      inset: var(--space-md);
      border: 1px solid var(--color-white);
      border-radius: var(--radius-md);
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      overflow: hidden;
      inset: 0;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 0;
    }

    &.casino {
      &::after {

        background-position: bottom center;
      }
    }

    &.sport {
      &::after {

        background-position: bottom center;
      }
    }

    &.live-sport {
      &::after {

        background-position: bottom right;
      }
    }

    &.live-casino {
      &::after {

        background-position: bottom right;
        @include mobile {
          background-position: bottom left;
        }
      }
    }
  }

  // title and action wrapper
  .card-content {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center;
    gap: var(--space-md);
    text-align: center;
    z-index: 2;
  }

  // card title
  .card-title {
    color: var(--color-white);
    font-size: var(--text-xl);
    font-weight: 800;
    text-transform: uppercase;
    z-index: 1;
    padding: 0;
    @include tablet {
      font-size: var(--text-2xl);
    }
  }

  .card-background {
    background: var(--transparent-black-gradient-v);
    position: absolute;
    inset: 0;
    z-index: 1;
  }
}
