// PAZURU

.pagination-wrapper {
  margin-top: 10px;
}

ul.pagination {
  @apply flex gap-2 items-center justify-center list-none p-0;

  .break {
    background: unset;
    border: unset;
    box-shadow: unset;
    a {
      width: auto;
      height: auto;
    }
  }
}
