:root {
  --text-color: var(--color-white);
  --text-color-faded: var(--color-neutral-3);

  // --color-background: var(--color-neutral-darkest);
  // --color-foreground: var(--color-neutral-dark);
  // --color-foreground-1: var(--color-dark-blue);

  --modal-bg-color: var(--color-neutral-1);

  // --input-bg: rgba(255, 255, 255, 0.05);

  --ticket-bg: var(--color-dark-blue);
  --ticket-row-bg: var(--color-dark-blue);
  --ticket-row-border: var(--color-neutral-dark);
  --ticket-row-highlight: var(--color-darker-blue);
  --ticket-accent-bg: var(--color-neutral-darkest);

  @include tablet {
    --ticket-accent-bg: var(--color-neutral-dark);
  }
  @include mobile {
    --ticket-accent-bg: var(--color-neutral-dark);
  }

  --jackpot-details-bg-first: var(--color-dark-blue);
  --jackpot-details-bg-second: var(--transparent-darker);

  --docs-dropdown-bg: var(--color-darkish-blue);
  --calendar-col-bg-1: #2f3442;
  --calendar-col-bg-2: #262c3a;
  --verification-step-bg: var(--color-darkish-blue);
  --rtp-transition-bg: var(--color-neutral-dark);
}

/* Pazuru */
body {
  @apply bg-[#F5F5F5] text-black bg-cover antialiased;
  @apply dark:bg-tertiary dark:text-tertiary-50;
}
.text-gradient {
  background: linear-gradient(90deg, #ff5811 0%, #fb20ff 40%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  filter: drop-shadow(0 0 15px #e30fa4);
}
.text-gradient-animation {
  animation: text-gradient-flicker 2s infinite alternate;
}

* {
  // scrollbar-width: none; /* Firefox 64 */
  // -ms-overflow-style: none; /* Internet Explorer 11 */
  // &::-webkit-scrollbar {
  //   /** WebKit */
  //   display: none;
  // }
  &::-webkit-scrollbar {
    @apply w-1.5 md:w-2;
  }
  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  &::-webkit-scrollbar-thumb {
    @apply bg-tertiary-200/80 rounded;
  }
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-tertiary-200;
  }
}

iframe {
  color-scheme: auto;
}
