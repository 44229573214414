// hide input number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

label {
  position: relative;

  &.small-grey {
    display: flex;
    flex-direction: column;

    font-size: var(--text-xs);
    font-weight: 500;
    text-transform: uppercase;
    color: var(--color-neutral-3);
    letter-spacing: 0.5px;

    .info {
      position: absolute;
      color: var(--color-primary);
      right: 0;
      bottom: var(--space-md);
      transform: translateY(-50%);
    }
  }
}

// [data-theme='dark'] {
//   .input-checkbox input:checked ~ span {
//     color: var(--color-white);
//     font-weight: 500;
//   }
// }

// Default text input
.input-field {
  width: 100%;
  height: var(--input-height);
  max-width: var(--max-input-width);

  padding: 0 var(--space-lg);
  background: var(--input-bg);
  color: var(--color-white);

  border: 1px solid var(--color-border);
  border-radius: var(--input-radius);
  outline: unset;

  &::placeholder {
    color: var(--color-neutral-3);
  }

  &.outline {
    border: 1px solid var(--color-neutral-2);
  }

  // validations
  &.error {
    border: 1px solid var(--color-danger);

    &:active,
    &:focus,
    &:focus-visible {
      border: 1px solid var(--color-danger);
    }
  }

  &.error,
  &.valid {
    padding: 0 var(--space-4xl) 0 var(--space-lg);
  }

  &:disabled,
  &:read-only {
    border: unset;
    background: var(--color-grey-9);
    font-weight: 600;
    color: var(--color-grey-1);
  }
}

.input-password {
  .input-field {
    padding: 0 70px 0 var(--space-lg);
  }
}

.password-hints {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-lg);
  margin-top: var(--space-sm);
  width: 100%;
  .password-hint {
    display: flex;
    align-items: center;
    gap: var(--space-xs);
    font-size: var(--text-sm);
    white-space: nowrap;
  }
}

//used in account information
.input-transparent {
  width: 100%;
  height: var(--input-height);
  background: var(--color-white);
  color: var(--color-black);
  font-size: var(--text-md);
  border-bottom: 1px solid var(--color-neutral-1);

  &.with-info {
    padding-right: 100px;
  }

  &:focus {
    outline-width: 0;
    border-bottom: 1px solid var(--color-accent);
  }
}

.input-with-btn {
  display: flex;
  .input-field {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .btn-small {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: auto;
  }
}

.input-code {
  @extend .input-field;
  width: 50px;
  height: 50px;
  padding: 0 var(--space-sm);
  text-align: center;

  color: var(--color-primary);
  font-size: var(--text-lg);
  font-weight: 500;

  @include mobile {
    width: 40px;
    padding: 0 var(--space-xs);
  }
}

// Special input styles

.input-highlighted {
  border: 1px solid var(--color-primary);
}

// Input Radio

/* The container */
.input-radio {
  max-width: var(--max-input-width);
  display: block;
  position: relative;
  padding-left: var(--space-3xl);
  cursor: pointer;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .label {
    color: var(--color-neutral-6);
    font-size: var(--text-md);
    text-align: left;
    line-height: 22px;

    &.large {
      font-size: var(--text-lg);
    }
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: var(--color-white);
    border: 2px solid var(--color-neutral-6);
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    border-color: var(--color-accent);
  }

  /* Highlight on select */
  input:checked ~ .label {
    color: var(--color-primary);
    font-weight: 500;
  }

  /* Highlight on select */
  input:checked ~ .checkmark {
    border: 2px solid var(--color-primary);
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--color-primary);
  }
}

// Input Checkbox

/* The container */
.input-checkbox {
  display: block;
  position: relative;
  // padding-top: 2px;e
  padding-left: var(--space-2xl);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  color: var(--text-color-faded);

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .label {
    font-size: var(--text-sm);
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 2px solid var(--color-neutral-3);
    border-radius: var(--radius-xs);
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    border: 2px solid var(--color-accent);
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    border: 2px solid var(--color-primary);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid var(--color-primary);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* Checkbox input on colored backgrounds */
  &.inverted {
    .label {
      font-size: var(--text-md);
    }
    .checkmark {
      border: 2px solid var(--color-white);
      background: var(--color-white);
    }
    input ~ span {
      color: var(--color-white);
    }
    input:checked ~ .checkmark {
      border: 2px solid var(--color-accent);
      background: var(--color-accent);
    }
    input:checked ~ span {
      color: var(--color-white);
      font-weight: 600;
    }
    .checkmark:after {
      border: solid var(--color-white);
      border-width: 0 2px 2px 0;
    }
  }
}

/* Switch */
.switch-wrapper {
  display: flex;
  align-items: center;
  gap: var(--space-md);
  cursor: pointer;

  .switch-label {
    color: var(--color-white);
    font-weight: 600;
    font-size: var(--text-md);
    line-height: 1;
    padding-top: var(--space-xs);
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 32px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-grey-5);
    border: 1px solid var(--color-grey-5);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 8px;
    bottom: 6px;
    background-color: var(--color-grey-1);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--color-grey-8);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--color-primary);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 16px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

/* Date Picker
--------------------- */
/* Range
--------------------- */

.birthdate-picker-group {
  display: flex;
  align-items: center;
  gap: var(--space-md);
  max-width: var(--max-input-width);
  margin: var(--space-md) auto 0 auto;

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: var(--color-primary);
  }
}

.date-picker-group {
  display: flex;
  align-items: center;
  gap: var(--space-md);
  max-width: var(--max-input-width);
  margin: var(--space-md) auto 0 auto;

  input {
    width: 100%;
    height: var(--input-height);
    max-width: var(--max-input-width);

    padding: 0 var(--space-xl);
    background: var(--color-white);
    color: var(--color-black);

    border: 2px solid transparent;
    border-radius: var(--input-radius);
    outline: unset;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);

    &::placeholder {
      color: var(--color-neutral-3);
    }

    &:active,
    &:focus,
    &:focus-visible {
      border: 2px solid var(--color-accent);
    }

    &.outline {
      border: 1px solid var(--color-neutral-4);
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: var(--color-primary);
  }
}

.birthdate-picker {
  .react-datepicker__navigation--years {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
  }
  .react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
  }
}

.interval-date-picker-group {
  @apply flex items-center gap-2.5 max-w-md mt-2.5 mx-auto mb-0;
  input {
    @apply w-full py-2 max-w-md px-4 bg-white dark:bg-tertiary-600 rounded outline-0 text-tertiary text-xs dark:text-white border border-tertiary-100 font-semibold dark:border-tertiary-400;

    &::placeholder {
      @apply text-tertiary;
    }

    &:active,
    &:focus,
    &:focus-visible {
      @apply ring-2 ring-tertiary-300;
    }

    &.outline {
      @apply border border-tertiary-200;
    }
  }
}

/* Progress Bar
--------------------- */

progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  appearance: none;

  width: 100%;
  height: 6px;
  overflow: hidden;

  &::-webkit-progress-value {
    background: var(--color-primary);
  }
  &::-webkit-progress-bar {
    background-color: var(--transparent-darker);
  }
}

/* Search Input
--------------------- */

.search-input {
  display: flex;
  align-items: center;
  position: relative;

  .icon {
    position: absolute;
    left: var(--space-lg);
  }

  input {
    background: transparent;
    border-radius: var(--radius-md);
    outline: 4px solid var(--color-grey-8);
    border: 1px solid var(--color-darkish-blue);
    font-weight: 600;
    height: var(--input-height);
    width: 100%;
    color: var(--color-white);
    padding: 0 var(--space-xl) 0 var(--space-4xl);

    &::placeholder {
      color: var(--color-white);
      font-weight: 700;
    }
  }
}

// PAZURU
.form-input {
  @apply w-full rounded sm:rounded leading-[22px] font-normal text-base text-tertiary placeholder:opacity-50 border dark:placeholder-white border-tertiary-100 ring-0 outline-none shadow-none p-2.5 px-4  h-auto max-w-none bg-tertiary-50;
  @apply dark:bg-tertiary-800 dark:text-white dark:border-tertiary-400;
  &:focus {
    @apply ring-0 outline-none shadow-none border-primary-500;
    @apply dark:border-primary-200;
  }
}
.form-label {
  @apply text-xs font-semibold text-primary-100 mb-1 block;
}
.form-checkbox {
  @apply rounded w-4 h-4 cursor-pointer text-primary-500 focus:ring-0 focus:ring-offset-0 focus:outline-none focus-visible:ring-0 focus-visible:outline-none dark:bg-tertiary-600 dark:border-tertiary-200 dark:text-primary-500 dark:checked:bg-primary-500 dark:checked:hover:bg-primary-500;
}
.form-radio {
  @apply w-3.5 h-3.5 text-transparent border border-primary-500 hover:border-primary-500 transition-all bg-transparent relative checked:border-primary-500 after:hidden checked:after:block checked:hover:bg-transparent focus:border-primary-500 checked:hover:border-primary-500 checked:focus:bg-primary-500 after:absolute after:w-2 after:h-2 after:bg-primary-500 after:inset-0 after:m-auto after:rounded-full focus:ring-0 focus:ring-offset-0 focus:checked:border-primary-500 focus:checked:bg-transparent;
}

.input-wrapper {
  @apply relative flex-col-reverse w-full max-w-none text-left items-start mb-5 flex;
  .input-field {
    @apply form-input max-w-none;
  }
  label {
    @apply text-xs font-semibold text-primary-100 mb-1 block;
  }
  .input-error {
    @apply m-0 mt-0.5 font-medium text-xs text-error/70;
  }
  .input-feedback {
    @apply top-8  absolute right-3 flex gap-2;
  }

  .feedback {
    margin-top: var(--space-sm);
  }
}

// Dropdown select
.dropdown-select {
  @apply w-full max-w-none;
  .select__control {
    @apply h-auto bg-white py-2 cursor-pointer dark:bg-tertiary-600 dark:text-white border border-solid border-tertiary-100 dark:border-tertiary-400  rounded sm:rounded font-normal text-sm text-tertiary  #{!important};
    @apply dark:bg-tertiary-800 dark:text-white dark:border-tertiary-400 #{!important};

    .select__input {
      @apply border-none ring-0 outline-none shadow-none font-semibold #{!important};
    }
  }

  .select__placeholder {
    @apply text-sm font-normal dark:text-white/70 #{!important};
  }

  .select__control--is-focused,
  .select__control--is-focused:hover {
    box-shadow: unset;
    outline: unset;
  }
  .select__single-value {
    @apply dark:text-white #{!important};
  }
  .select__indicator {
    @apply w-4 dark:invert;
  }

  .select__indicator-separator {
    display: none;
  }
  .select__menu {
    @apply rounded bg-white overflow-hidden dark:bg-tertiary-700;
  }
  .select__menu-list {
    @apply px-1 py-2;
  }
  .select__option {
    @apply text-sm font-normal text-tertiary dark:text-white;
  }
  .select__option--is-focused {
    background-color: transparent;
  }
  .select__option--is-selected,
  .select__option:active,
  .select__option:hover {
    @apply text-sm rounded font-medium text-black bg-black/10 dark:text-white dark:bg-tertiary-600;
  }
  .select__option {
    cursor: pointer;
  }

  &.filter-dropdown-select {
    .select__control {
      @apply rounded py-1 md:w-auto w-full #{!important};
    }
  }
}

// Dropdown select variations
.dropdown-select {
  .select__single-value,
  .select__placeholder,
  .select__indicator {
    @apply text-black text-base font-semibold;
  }
}

.search-dropdown {
  .select__control {
    min-width: 200px;
    height: 45px;
    background: var(--color-dark);
    border: 2px solid var(--color-grey-9);
    border-radius: 35px !important;
    color: #fff;
    position: relative;
    border-radius: 35px;
  }
}

.mobile-prefix-dropdown {
  .select__control,
  .select__value-container,
  .select__input-container {
    @apply p-0 #{!important};
  }
  .select__input-container,
  .select__single-value {
    @apply m-0 font-normal;
  }
  .select__control {
    @apply border-none #{!important};
  }
  .select__indicator {
    @apply w-fit;
  }
  .select__menu {
    @apply w-20;
  }
}

.interval-date-picker-group input {
  font-size: 16px;
}
.phone-number-input {
  // @apply py-0 #{!important};
  @apply form-input text-base md:text-sm;
  .PhoneInputCountry {
    @apply pr-3 mr-0 gap-1;
    .PhoneInputCountrySelect {
      @apply bg-white dark:bg-tertiary-700 #{!important};
      option {
        @apply text-tertiary dark:text-white #{!important};
      }
    }
    .PhoneInputCountrySelectArrow {
      @apply w-2 h-2;
    }
  }
  input {
    @apply bg-transparent border-transparent w-full p-0 pl-3 border-l border-l-tertiary/50 focus:outline-none focus-visible:ring-0 #{!important};
  }
}

.prefix-dropdown {
  .select__control {
    @apply py-0.5 #{!important};
  }
  .select__control .hideme {
    @apply hidden;
  }
  .select__indicator {
    @apply pl-0;
  }
  .select__indicator {
    @apply hover:text-white/80;
  }
  .select__menu-list {
    @apply max-h-36 md:max-h-48;
  }
}
