.recent-winners-wrapper {
    --swiper-navigation-sides-offset: 0;
    display: flex;
    flex-direction: column;
    gap: var(--space-md);
    padding: var(--space-lg) var(--space-md);

    background: var(--color-grey-8);
    border-radius: var(--radius-md);

    @include desktop {
      padding: var(--space-xl) var(--space-lg);
    }

    .category-header {
      display: flex;
      align-items: center;
      gap: var(--space-sm);
    }
  
    .category-title { 
      font-weight: 700;
    }

  .swiper {
    width: 100%;
    max-width: 100%;
    // fade slider end to suggest continuity
    &::before,
    &::after {
        position: absolute;
        top: 0;
        content: '';
        height: 100%;
        width: 10px;
        z-index: 2;
  
        @include desktop {
          width: 50px;
        }
    }
    
    &::before {
        left: 0;
        background-image: linear-gradient(
          to right,
          var(--color-grey-8),
          50%,
          rgba(41, 50, 65, 0.00)
        );
    }
    &::after {
        right: 0;
        background-image: linear-gradient(
          to left,
          var(--color-grey-8),
          50%,
          rgba(41, 50, 65, 0.00)
        );
    }
  }

  .jackpot-slide {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: var(--space-md);
    margin: 0 var(--space-sm);
  
    .slide-image {
      min-width: 70px;
      min-height: 70px;
      aspect-ratio: 14/9;
      position: relative;
      overflow: hidden;
      border-radius: var(--radius-md);
      @include tablet {
        min-height: 80px;
      }
    }
    .slide-content {
      display: flex;
      gap: var(--space-xs);
      flex-direction: column;
      width: 100px;
      font-size: var(--text-sm);
      font-weight: 600;
  
      @include mobile {
        width: 100px;  
      }
    }
  
    .game-title {
      color: var(--color-primary);  
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 700;
      font-size: var(--text-xs);
      margin-bottom: var(--space-sm);
    }
  
    .jackpot {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: var(--space-xs);
      font-size: var(--text-md);
      text-transform: uppercase;
      font-weight: 700;
      span {
        font-size: var(--text-sm);
        font-weight: 600;
      }
    }
  
    .win-info {
      font-size: var(--text-xs);
      color: var(--color-grey-1);
      font-weight: 600;
    }
  }
}
