.top-nav-wrapper {
  background: var(--header-bg);
  margin-bottom: var(--gap);
  position: fixed;
  top: 0;
  z-index: 401;
  width: 100%;
}

.top-nav {
  display: flex;
  // padding: var(--space-md) var(--gap);
  height: var(--header-height);

  &.minimized {
    --header-height: 60px;
  }

  .menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: var(--radius-sm);
    background-color: var(--color-grey-8);
    cursor: pointer;

    .icon {
      width: 18px;
      height: 14px;
    }
  }

  .main-logo {
    display: flex;
  }

  .nav-search-input-wrapper {
    input {
      max-width: 184px;
    }
  }

  .layout-header-nav {
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: none;
    gap: var(--space-xl);
    flex-grow: 1;

    a {
      font-size: var(--text-sm);
      font-weight: 600;
      text-decoration: none;

      color: var(--color-grey-1);
      padding: var(--space-sm) 0 var(--space-sm) 0;

      &:hover {
        color: var(--color-secondary);
      }
    }

    .active {
      a {
        color: var(--color-primary);
      }
    }
  }

  .title {
    flex: 1;
    color: var(--color-white);
    font-size: var(--text-sm);
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  .pending {
    padding-right: 80px;
  }

  .close {
    width: 24px;
    display: flex;
    justify-content: flex-end;
    .icon {
      cursor: pointer;
    }
  }

  .header-actions {
    display: flex;
    gap: var(--space-md);
    margin-left: auto;
  }

  .header-user-actions {
    position: relative;
    display: flex;
    gap: var(--space-md);
    align-items: center;
    margin-left: auto;
  }
}

.layout-header-sub-nav {
  display: flex;
  height: 30px;
  gap: var(--gap);
  margin: 0 auto;
  padding: 0 var(--gap);
  background-color: var(--color-neutral-dark);
  border-bottom: 1px solid var(--color-neutral-darkest);

  a {
    color: var(--color-neutral-3);
    font-size: var(--text-2xs);
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    line-height: 1;

    &:hover {
      color: var(--color-white);
    }
  }
}

.login-alert-wrapper {
  padding: var(--space-sm);
  text-align: center;
  background: var(--color-neutral-darkest);
  color: var(--color-primary);
  font-size: var(--text-xs);
  text-transform: uppercase;
  a {
    color: var(--color-accent);
    text-decoration: none;
  }
}
.bottom-nav {
  display: flex;
  flex-direction: row;
  flex: 1;
  position: fixed;
  bottom: 0;

  width: 100%;
  height: var(--header-height);
  padding: var(--space-md);
  background: var(--color-background);
  border-top: 1px solid var(--color-grey-9);
  z-index: 400;

  .nav-item {
    display: grid;
    gap: var(--space-sm);
    flex: 1;

    align-items: center;
    justify-items: center;

    color: var(--color-grey-1);
    font-size: var(--text-xs);
    cursor: pointer;

    &:hover {
      color: var(--color-primary);
      .icon {
        transition: 0.2s ease;
        background-color: var(--color-primary);
      }
    }
  }
}

.header-tabs {
  display: flex;
  flex-direction: column;
  gap: var(--space-lg);
  margin-bottom: var(--space-lg);
}

.new-label {
  background-color: #ff0000;
  color: #ffffff;
  font-size: 12px;
  padding: 2px 2px;
  border-radius: var(--radius-sm);
  margin-left: 4px;
  font-weight: 900;
}

.nav-menu {
  .close-btn-round {
    align-self: flex-start;
  }
}

.nav-balance-wrapper {
  position: relative;
  white-space: nowrap;
}

.nav-balance-details {
  position: absolute;
  top: 50px;
  right: 0;
  background: var(--color-grey-8);
  border-radius: var(--radius-md);

  display: flex;
  flex-direction: column;
  gap: var(--space-md);
  width: 390px;
  padding: var(--space-xl);

  @include tablet {
    top: 40px;
  }

  @include mobile {
    padding: var(--space-lg);
    position: fixed;
    top: var(--header-height);
    width: 100%;
    border-radius: unset;

    &::after {
      content: "";
      height: 64px;
      background: var(--gradient-faded-grey-8);
      width: 100%;
      bottom: -64px;
      left: 0;
      position: absolute;
    }
  }
}
