@import "./breakpoints.scss";

:root {
  // new
  --color-primary: #c2943f;
  --color-secondary: #e9d694;
  --color-dark: #151719;
  --color-grey-0: #ededed;
  --color-grey-1: #a2abb0;
  --color-grey-5: #585a60;
  --color-grey-8: #293241;
  --color-grey-9: #232629;

  --purple-300: #9d31e0;

  --color-highlight: var(--purple-300);

  --color-background: var(--color-dark);
  --color-foreground: var(--color-dark);

  /* borders */
  --color-border: var(--color-grey-5);
  /* border radius */
  --radius-xs: 2px;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 16px;
  --radius-xl: 28px;

  /* layout elements */
  --drawer-width: 100%;

  /* header */
  --header-height: 60px;
  --header-bg: var(--color-dark);
  @include desktop {
    --header-height: 100px;
  }

  /* inputs */
  --input-height: 36px;
  --input-radius: var(--radius-md);
  --input-bg: var(--color-grey-8);
  --input-label-bg: var(--color-grey-1);
  --input-label-text: var(--color-dark);

  // old
  --color-foreground-1: var(--color-dark);
  --main-font: "Source Sans Pro", sans-serif;

  --color-white: #ffffff;

  --color-neutral-lighter: #eef3f8;

  --color-neutral-1: #f7f7f7;
  --color-neutral-2: #e7e9ef;
  --color-neutral-3: #bebebe;
  --color-neutral-4: #76766f;
  --color-neutral-5: #626b78;
  --color-neutral-6: #4d4d4d;
  --color-neutral-7: #37393d;
  --color-neutral-8: #24272c;

  --color-neutral-dark: #262c3a;
  --color-neutral-darker: #222836;
  --color-neutral-darkest: #141722;

  --color-black: #000000;

  --color-darkish-blue: #5a637c;
  --color-dark-blue: #40475b;
  --color-darker-blue: #373d50;

  --color-lightest-blue: #e7e9ef;
  --color-light-blue: #ced8df;

  --color-primary-light: hsl(159, 62%, 90%);
  --color-primary-900: hsl(135, 78%, 24%);
  --color-primary-900er: hsl(134, 82%, 11%);

  // #fcc00a
  --color-accent-light: hsl(45, 100%, 80%);
  --color-accent: #ffe402;
  --color-accent-dark: hsl(45, 100%, 40%);

  --color-danger-light: hsl(344, 100%, 90%);
  --color-danger: hsl(344, 100%, 50%);
  --color-danger-dark: hsl(344, 100%, 40%);

  --color-info-light: hsl(194, 75%, 90%);
  --color-info: hsl(194, 75%, 55%);
  --color-info-dark: hsl(194, 75%, 45%);

  --elevation-1: -5px -5px 10px rgba(35, 38, 41, 0.3),
    5px 5px 10px rgba(0, 0, 0, 0.3);
  --elevation-2: -5px -5px 10px rgba(35, 38, 41, 0.5),
    5px 5px 10px rgba(0, 0, 0, 0.6);
  --elevation-3: -5px -5px 10px rgba(35, 38, 41, 0.7),
    5px 5px 10px rgba(0, 0, 0, 0.8);

  --elevation-4: var(--elevation-1);
  --elevation-5: var(--elevation-1);

  --border-color: rgba(255, 255, 255, 0.2);
  --transparent-white: rgba(255, 255, 255, 0.8);
  --transparent-black: rgba(0, 0, 0, 0.6);
  --transparent-primary: rgba(48, 207, 151, 0.2);
  --transparent-darker: rgba(0, 0, 0, 0.2);
  --transparent-dark: rgba(0, 0, 0, 0.08);

  --color-shuftipro: rgba(239, 83, 80, 1);

  --box-shadow: 2px 4px 10px rgba(35, 40, 57, 0.07);
  // --box-shadow: -5px -5px 10px rgba(35,38,41,.7), 5px 5px 10px rgba(0,0,0,.8);

  /* font-size */
  --text-3xs: 8px;
  --text-2xs: 10px;
  --text-xs: 12px;
  --text-sm: 14px;
  --text-md: 16px;
  --text-lg: 18px;
  --text-xl: 20px;
  --text-2xl: 24px;
  --text-3xl: 32px;
  --text-4xl: 48px;
  --text-5xl: 64px;
  --text-6xl: 76px;

  /* spacing */
  --space-xs: 2px;
  --space-sm: 5px;
  --space-md: 10px;
  --space-lg: 15px;
  --space-xl: 20px;
  --space-2xl: 25px;
  --space-3xl: 30px;
  --space-4xl: 40px;
  --space-5xl: 60px;

  --space-footer: 60px;

  --gap: var(--space-md);
  @include tablet {
    --gap: var(--space-lg);
  }
  @include desktop {
    --gap: var(--space-lg);
  }

  /* widths */
  --max-input-width: 400px;
  --small-box-width: 400px;
  --max-button-width: 400px;
  --sidebar-width: 250px;
  --content-width: 1400px;

  /* heights */

  --btn-border-radius: var(--radius-md);
  --btn-font-size: var(--text-md);
  --btn-font-weight: 800;

  --btn-large-height: 42px;
  --btn-small-height: 32px;

  --btn-elevation: var(--elevation-1);

  --card-border-radius: var(--radius-md);
  --card-elevation: var(--elevation-1);
}

// Z-INDEX MAP
// 000–199: Elements and Components
// 200–299: Element and Component Drop Downs
// 300–399: Secondary Navigation
// 400–499: Header / Footer
// 500–599: Primary Navigation
// 600–699: Full Screen Features
// 700–799: Special Cases
// 800–899: Modals / Dialog Windows
// 900–999: Notifications
