.loading {
  z-index: 800;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: rgba(0, 0, 0, 0.9);
  }

  .spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    text-align: center;
  }
  .title {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 800;
    margin-top: 5px;
    letter-spacing: 1px;
    cursor: default;
  }
}

.spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.spinner div {
  transform-origin: 20px 20px;
  animation: spinner 1.2s linear infinite;
}
.spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 18px;
  width: 3px;
  height: 9px;
  border-radius: 20%;
  background: var(--color-white);
}
.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.time-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.time-circle {
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
}

.time-path {
  stroke: var(--text-color-faded); /* Change this to the color you want */
  stroke-linecap: round;
}

@keyframes spin {
  0% {
    stroke-dasharray: 0, 150;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 150, 0;
    stroke-dashoffset: 0;
  }
}
