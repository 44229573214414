.group-widget {
  max-width: 770px;
  margin: 0 auto var(--space-2xl);
  position: relative;

  @include tablet {
    max-width: 480px;
  }

  .swiper-wrapper {
    max-width: 700px;
  }
  
  &.live-casino {
    max-width: 420px;
    .swiper-wrapper {
      max-width: 350px;
    }
  }
}



.group-slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  #swiper-button-prev,
  #swiper-button-next {
    flex-shrink: 0;
    cursor: pointer;
  }

  @include mobile {
    #swiper-button-prev,
    #swiper-button-next {
      display: none;
    }
  }

  #swiper-button-prev {
    transform: rotate(180deg);
  }
}

.group-slider.swiper {
  display: flex;
  padding: 0 6px;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    content: '';
    height: 100%;
    width: 20px;
    z-index: 2;

    @include desktop {
      width: 30px;
    }
  }
  &::before {
    width: 10px;
    left: 0;
    background-image: linear-gradient(
      to right,
      var(--color-foreground),
      50%,
      rgba(255, 255, 255, 0)
    );
  }
  &::after {
    right: 0;
    background-image: linear-gradient(
      to left,
      var(--color-foreground),
      50%,
      rgba(255, 255, 255, 0)
    );
  }

  .group-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-sm);
  }

  .group-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 52px;
    height: 52px;
    aspect-ratio: 1;

    border: 0;
    border-radius: 50%;
    background-color: var(--color-grey-8);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    z-index: 1;
    transition: background 0.2s ease-out;
    margin: var(--space-md) auto 0;

    .icon {
      width: 28px;
      height: 28px;
    }
  }

  .group-label {
    flex: 1;
    margin-top: var(--space-md);
    color: var(--color-grey-1);
    font-size: var(--text-xs);
    font-weight: 900;
    text-align: center;
  }

  .group-item {
    &:hover {
      cursor: pointer;
    }

    &:hover,
    &.active {
      .group-icon {
        background: var(--color-primary);
        .icon {
          background: var(--color-white);
        }
      }
      .group-label {
        color: var(--color-white);
      }
    }
  }
}

.group-search {
  display: flex;
  gap: var(--space-lg);
  position: relative;
  margin-top: var(--space-2xl);

  .search-input {
    width: 100%;
    input {
      background: var(--color-dark);
      border: 2px solid var(--color-grey-9);
      border-radius: 35px;
      background-clip: unset;
      padding-left: 70px;
      padding-right: 10px;
      color: var(--color-white);
      font-weight: 700;
      height: 45px;
      width: 100%;
      outline: none;

      @include mobile {
        padding-left: 50px;
      }

      &:focus {
        box-shadow: 0 0 6px 0.1rem var(--color-primary);
      }
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
    }
  }
  .dropdown-select {
    max-width: 300px;
  }
}
