.mybets-wrapper {
  .header-tabs {
    display: flex;
    flex-direction: column;
    gap: var(--space-lg);
    margin-bottom: var(--space-lg);
    z-index: 5;
    position: relative;
  }

  .btn-group {
    margin-bottom: var(--space-md);
  }
  .empty-state {
    text-align: center;
  }
}

.ticket-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-xl);
}

.ticket-body {
  display: flex;
  flex-direction: column;
  border-bottom: 5px solid var(--ticket-accent-bg);

  @include mobile {
    margin: 0 calc(-1 * var(--space-md));
  }

  &.ticket-history.card {
    padding: 0;
    border-radius: var(--radius-md);
    font-weight: 600;
    margin-top: var(--space-2xl);
    .ticket-row:nth-child(2n) {
      background-color: var(--color-white);
    }
  }
}

.ticket-title-wrapper {
  align-self: flex-start;
  margin-left: var(--space-xl);

  z-index: 1;
  position: relative;

  .ticket-title {
    min-width: 30%;
    padding: var(--space-md) var(--space-lg);
    background: var(--ticket-accent-bg);
    border-top-left-radius: var(--radius-md);
    border-top-right-radius: var(--radius-md);
    color: var(--color-white);
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;

    position: relative;
    z-index: 2;
  }

  .ticket-title-status {
    position: absolute;
    inset: 0;
    top: 10px;
    margin: 0 calc(-1 * var(--space-md));
    z-index: 1;
    border-top-left-radius: var(--radius-md);
    border-top-right-radius: var(--radius-md);
    &.won {
      background: var(--color-primary);
    }
    &.draw {
      background: var(--color-accent);
    }
    &.lost {
      background: var(--color-danger);
    }
    &.open {
      background: var(--color-info);
    }
  }
}

.ticket-header,
.ticket-row {
  display: flex;
  gap: var(--space-sm);
}

.ticket-header {
  color: var(--color-white);
  background: var(--ticket-accent-bg);
  justify-content: space-between;
  position: relative;
  z-index: 2;
  align-items: center;
  padding: var(--space-md) var(--space-xl);

  & > * {
    flex: 1;
  }

  .ticket-date {
    font-size: var(--text-sm);
  }
  .ticket-status {
    font-size: var(--text-md);
    font-weight: 600;
    text-align: right;
    &.won {
      color: var(--color-primary);
    }
    &.lost {
      color: var(--color-danger);
    }
    &.draw {
      color: var(--color-accent);
    }
    &.open {
      color: var(--color-info);
    }
  }
  // .copy-clipboard-row {
  //   display: flex;
  //   align-items: center;
  //   column-gap: var(--space-sm);
  //   justify-content: flex-end;
  //   font-size: var(--text-sm);
  // }
}

.ticket-row {
  border-top: 1px solid var(--ticket-row-border);
  background-color: var(--ticket-row-bg);

  &:last-child {
    border-bottom: unset;
  }

  &.won,
  &.draw,
  &.lost,
  &.open {
    cursor: pointer;
  }

  .col-left,
  .col-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--space-sm);
    padding: var(--space-lg) 0;
    &.won {
      color: var(--color-primary);
    }
    &.lost {
      color: var(--color-danger);
    }
    &.draw {
      color: var(--color-accent);
    }
    &.open {
      color: var(--color-info);
    }
  }

  .col-left {
    flex: 1;
    padding-left: var(--space-xl);
    @include mobile {
      padding-left: var(--space-lg);
    }
  }
  .col-right {
    align-items: flex-end;
    padding-right: var(--space-md);
    text-align: right;
  }

  .col-action {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--transparent-dark);
    padding: var(--space-sm) var(--space-md);

    .icon {
      background-color: var(--text-color);
    }
  }

  .result,
  .odds {
    display: flex;
    align-items: center;
    align-items: flex-start;
    line-height: 1;
    gap: var(--space-sm);
  }
  .event,
  .date {
    font-size: var(--text-sm);
    color: var(--text-color-faded);
  }
}

.ticket-share {
  display: flex;
  align-self: flex-end;
  gap: var(--space-sm);
  padding: var(--space-sm) var(--space-lg);
  background: var(--ticket-accent-bg);
  border-bottom-left-radius: var(--radius-md);
  border-bottom-right-radius: var(--radius-md);
  color: var(--color-white);
  font-weight: 600;
  text-align: center;
  cursor: pointer;

  position: relative;
  z-index: 2;

  @include mobile {
    margin-right: var(--space-md);
  }

  &:hover {
    color: var(--color-primary);
    .icon {
      background-color: var(--color-primary);
    }
  }
}

.ticket-row-details,
.cashout-details {
  display: flex;
  flex-direction: column;
  background-color: var(--color-foreground-1);

  .details-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: var(--text-xs);
    gap: var(--space-sm);
    padding: var(--space-sm) var(--space-lg);

    &:nth-child(2n + 1) {
      background-color: var(--transparent-dark);
    }

    .col-left {
      display: flex;
      gap: var(--space-md);
      align-items: center;
    }

    .col-right {
      font-weight: 600;
      &.won {
        color: var(--color-primary);
      }
      &.lost {
        color: var(--color-danger);
      }
      &.draw {
        color: var(--color-accent);
      }
      &.open {
        color: var(--color-info);
      }
    }
  }
}

.ticket-wrapper-old {
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);
  padding-bottom: var(--space-2xl);
  margin-bottom: var(--space-2xl);
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-neutral-1);
  }

  .ticket-actions {
    display: flex;
    justify-content: space-between;
    .btn-small {
      width: 100%;
      max-width: 250px;
      margin-right: var(--space-xl);
    }
    .btn-circle-small {
      flex-shrink: 0;
    }
  }
  .ticket-info {
    display: grid;
    grid-template-columns: 60% 40%;
    color: var(--color-neutral-6);

    .col {
      display: flex;
      flex-direction: column;
      gap: var(--space-sm);
    }
    .col:last-child {
      align-items: flex-end;
      text-align: right;
    }
    .win-info {
      margin-top: auto;
    }
  }
  .show-more {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: var(--space-sm);
    color: var(--color-neutral-4);
    padding: 0 var(--space-md);
    cursor: pointer;
  }
}
