@tailwind base;
@tailwind components;
@tailwind utilities;

@import "abstracts/breakpoints";
@import "abstracts/variables";
@import "abstracts/gradients";
@import "abstracts/theme";

@import "base/animations";
@import "base/reset";
@import "base/general";
@import "base/typography";
@import "base/buttons";
@import "base/modal";
@import "base/drawer";
@import "base/loader";
@import "base/menu";
@import "base/swiper";
@import "components/widget_";

@import "components/toggle_theme";
@import "components/layout";
@import "components/nav";
@import "components/tab_nav";
@import "components/icon";
@import "components/alert";
@import "components/footer";
@import "components/jackpot_counter";
@import "components/jackpots_group";
@import "components/jackpot_card";

@import "components/forms";
@import "components/select";
@import "components/pagination";

@import "components/game_of_the_week";
@import "components/main_slider";
@import "components/group_slider";
@import "components/game_group_swiper";
@import "components/vendors_widget";
@import "components/login";
@import "components/signup";
@import "components/recent_winners";

@import "react-loading-skeleton/dist/skeleton.css";
@import "components/skeleton";

@import "components/profile";

@import "pages/home";
@import "pages/casino";
@import "pages/game";
@import "pages/rtp";
@import "pages/sports";

@import "pages/profile";
@import "pages/user_info_card";
@import "pages/account_balance";
@import "pages/account_bonuses";
@import "pages/account_withdraw";
@import "pages/account_mybets";
@import "pages/account_transactions";
@import "pages/account_documents";
@import "pages/account_responsible";
@import "pages/account_information";
@import "pages/account_information";
@import "pages/account_sportsetting";
@import "pages/static";
@import "pages/promotions";
@import "pages/resetpassword";
@import "pages/session-info";
@import "pages/cms_page";
@import "pages/landing";
@import "pages/404";
@import "pages/games";
@import "pages/cashier";

@import "components/promotions_swiper";
