.rtp-game-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-sm);
}

.game-rtp-wrapper {
  display: flex;
  align-items: center;
  gap: var(--space-sm);
  border-radius: var(--radius-lg);
  background-color: var(--color-foreground);
  padding: var(--space-sm) var(--space-md);
  margin-top: calc(-1 * var(--space-xl));
  z-index: 9;

  .game-rtp {
    font-weight: 900;
    font-size: var(--text-md);
    line-height: 1;
    @include mobile {
      font-size: var(--text-xs);
    }

    .icon {
      width: 11px;
      height: 14px;
    }
  }
}

.rtp-widget {
  margin: var(--space-xl) 0;
  padding: var(--gap);
  border-radius: var(--radius-md);

  &.high {
    background-color: var(--color-danger);
    .swiper::after {
      background-image: linear-gradient(
        to left,
        var(--color-danger),
        50%,
        rgba(255, 255, 255, 0)
      );
    }
  }
  &.low {
    background-color: var(--color-info);
    .swiper::after {
      background-image: linear-gradient(
        to left,
        var(--color-info),
        50%,
        rgba(255, 255, 255, 0)
      );
    }    
  }


  @include mobile {
    padding: var(--gap);
  }
  .swiper {
    // width: calc(100% + var(--gap));
    // fade slider end to suggest continuity
    &::after {
      position: absolute;
      right: 0;
      top: 0;
      content: '';
      height: 100%;
      width: 20px;
      z-index: 2;

      @include desktop {
        width: 50px;
      }
    }
  }
}

.rtp-category-title {
  display: flex;
  align-items: center;
  gap: var(--space-md);
  margin: 0;
  font-weight: 700;
  position: relative;

  .icon {
    z-index: 3;

    &.small {
      width: 17px;
      height: 23px;
    }
  }

  span {
    z-index: 3;
  }
}

.rtp-category-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-lg);
  gap: var(--space-md);
}
// [data-theme='dark'] {
//   .rtp-category-header {
//     text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
//   }
// }

.rtp-category-games {
  .game-wrapper:first-child {
    grid-area: unset;
    .game-box,
    .game-buttons {
      aspect-ratio: 14/9;
    }
  }
}

.rtp-page {
  .page-header {
    text-align: center;
    padding: var(--gap);
    margin-bottom: var(--gap);
  }
  .page-title {
    margin: 0;
    font-size: var(--text-2xl);
    @include mobile {
      font-size: var(--text-sm);
    }
  }
  .page-subtitle {
    font-size: var(--text-sm);
    font-weight: 600;
    color: var(--color-darkish-blue);
    margin: var(--space-sm) 0 0 0;
  }
  .page-content > .container {
    display: flex;
    flex-direction: column;
    gap: var(--space-2xl);
    @include mobile {
      gap: unset;
    }

    .info-text {
      display: flex;
      flex-direction: column;
      font-size: var(--text-xs);
      gap: var(--space-sm);
    }
  }
}

.rtp-section {
  &.card {
    box-shadow: unset;
    padding: var(--space-lg);

    .swiper {
      width: calc(100% + var(--gap));
      // fade slider end to suggest continuity
      &::after {
        position: absolute;
        right: 0;
        top: 0;
        content: '';
        height: 100%;
        width: 20px;
        background-image: linear-gradient(
          to left,
          var(--color-foreground),
          50%,
          rgba(255, 255, 255, 0)
        );
        z-index: 2;

        @include desktop {
          width: 50px;
        }
      }
    }
    @include mobile {
      margin: 0 calc(-1 * var(--gap)) var(--space-xl);
    }
  }
}

.rtp-section-info {
  display: flex;
  align-items: center;
  gap: var(--space-sm);
  color: var(--transparent-white);
  margin-top: var(--gap);
  font-weight: 600;
  font-size: vaR(--text-xs);
  .icon {
    background-color: var(--transparent-white);
  }
}
