.swiper {
  .swiper-button-prev,
  .swiper-button-next {
    @apply bg-[#2D295F] bg-opacity-50 absolute w-8 h-8 rounded-full border-none text-white cursor-pointer text-xs;
    filter: drop-shadow(2px 0px 4px rgba(0, 0, 0, 0.17));
    &.swiper-button-disabled {
      @apply opacity-50;
    }
    .swiper-button-prev {
      @apply -left-3 #{!important};
    }
    .swiper-button-next {
      @apply -right-3 #{!important};
    }
    &:after {
      @apply text-white text-xs font-bold;
    }
  }
}
