.game-group {
    --swiper-navigation-top-offset: calc(50% - 15px);
    position: relative;
    &:after {
        position: absolute;
        right: 0;
        top: 0;
        content: "";
        height: 100%;
        width: 20px;
        background-image: linear-gradient(to left, var(--color-foreground), 50%, rgba(255, 255, 255, 0));
        z-index: 2;        
    }
}