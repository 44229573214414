.menu-header {
  color: var(--color-neutral-6);
  background-color: var(--color-white);
  margin: var(--space-md);
  border-radius: var(--radius-xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--space-md);

  &.active {
    background-color: var(--color-neutral-2);
  }

  .menu-header-icon {
    width: 32px;
    height: 32px;
    margin: var(--space-sm);
    background: var(--color-neutral-2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    &.active {
      background: var(--color-accent);
    }
  }

  .menu-header-text {
    flex-grow: 1;
  }

  .menu-header-subicon {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: var(--space-sm);
    background: var(--color-primary);
  }

  .menu-header-subtext {
    border-radius: 50%;
    margin: var(--space-sm);
    color: var(--color-primary);
  }
}

.menu-item {
  background-color: var(--color-white);
  color: var(--color-neutral-6);
  margin: var(--space-sm) var(--space-md);
  border-radius: var(--radius-xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: var(--space-md);

  .menu-item-icon {
    width: 32px;
    height: 32px;
    margin: var(--space-sm);
    background: var(--color-neutral-2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }

  .menu-item-text {
    flex-grow: 1;
  }

  .menu-item-subtext {
    border-radius: 50%;
    margin: var(--space-sm);
    color: var(--color-primary);
  }
}

.menu-group-title {
  margin: var(--space-xl) 0 var(--space-sm);
  text-transform: uppercase;
  font-size: var(--text-sm);
  font-weight: 800;
}

.menu-group {
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);
  .menu-link {
    text-decoration: none;

    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }

  .menu-group-item {
    display: flex;
    flex-direction: row;
    gap: var(--space-md);
    align-items: center;
    cursor: pointer;

    .item-name {
      flex: 1;
      display: flex;
      justify-content: space-between;
      font-size: var(--text-lg);
      font-weight: 500;
      color: var(--color-grey-1);
    }

    .item-count {
      color: var(--color-primary);
    }
  }

  .show-more {
    display: flex;
    justify-content: flex-end;
    gap: var(--space-sm);
    cursor: pointer;
    margin: var(--space-md) 0;

    .label {
      color: var(--color-primary);
      text-transform: uppercase;
      text-align: right;
      font-size: var(--text-xs);
      font-weight: 500;
    }
  }

  // Desktop styles
  .menu-group-subcategory {
    &:last-child {
      .item-name {
        border-bottom: unset;
      }
    }
  }
}

.menu-group:not(:last-child) {
  border-bottom: 1px solid var(--color-border);
  padding-bottom: var(--space-xl);
}

.menu-group.wiki-links {
  gap: var(--space-lg);
  .menu-group-item {
    padding: 0;
    .item-name {
      font-size: var(--text-md);
    }
  }
}
