.tab-nav {
    display: flex;
    flex-direction: column;
    
    .nav-row {
        display: flex;
        border-bottom: 1px solid var(--color-neutral-2);
        
        background: var(--color-neutral-2);
        &.parent-row {
            background: var(--color-white);
        }

        .nav-item {
            flex: 1;
            padding: var(--space-lg) 0;
            text-align: center;
            color: var(--color-neutral-4);
            cursor: pointer;

            &:hover {
                background: var(--color-neutral-3);
                color: var(--color-white);
            }

            &.active {
                background: var(--color-primary);
                color: var(--color-white);
                font-weight: 500;
            }
        }
    }

    .dropdown {
        flex-direction: column;
        .nav-item {
            // dropdown button
            &.active {
                display: flex;
                justify-content: center;
                gap: var(--space-md);
            }
        }
        .dropdown-content {
            flex-direction: column;
        }
    }
}