.drawer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  z-index: 0;

  .content {
    position: fixed;
    height: 100%;
    overflow: auto;
    z-index: 2;
    background-color: var(--color-foreground);
  }

  .obfuscator {
    position: fixed;
    z-index: 1;
  }
}

.drawer-left {
  top: 0;
  .content {
    display: flex;
    flex-direction: column;
    gap: var(--space-xl);
    width: var(--drawer-width);
    padding: var(--space-2xl);
    left: 0;
    top: 0;
  }
}

.drawer-right {
  top: 0;
  .content {
    right: 0;
    width: var(--drawer-width);
    height: 100vh;
  }
  .close {
    top: var(--space-lg);
    right: var(--space-lg);
    position: absolute;
  }
}

// body class
.drawer-login {
  top: 0;
  overflow: hidden;


  // full page drawer
  .drawer {
    top: var(--header-height);
    right: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - var(--header-height));
    background-color: var(--color-background);    

    .obfuscator {
      z-index: -1;
    }
  }  

  .drawer-content {
    height: 100%;;
    background-color: var(--color-background);
    z-index: 2;
  }

  @include mobile {
    top: var(--header-height);
    .content {
      right: 0;
      left: 0;
      width: 100%;
      height: calc(100vh - var(--header-height));
      background-color: var(--color-white);
    }  
  }

}

.drawer-open {
  visibility: visible;
  z-index: 500;

  -webkit-animation: fadeIn 0.3s ease-out;
  -moz-animation: fadeIn 0.3s ease-out;
  -o-animation: fadeIn 0.3s ease-out;
  animation: fadeIn 0.3s ease-out;
}

.obfuscator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.drawer-opened:not(.drawer-login) {
  overflow: hidden;
  .top-nav,
  .layout-header-sub-nav,
  .page-content,
  .game-page,
  .page-footer {
    filter: blur(5px) brightness(110%);
  }
}
