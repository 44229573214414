/**
 * Icon Component Styles
 * ----------------------
 * INDEX:
 * 1. Base Path & Base Style
 * 2. Icon Sizes
 * 3. Color Variations
 * ----------------------
 * 4. Arrow Icons
 * 5. Action Icons
 * 6. Utility Icons
 * 7. Navigation Icons
 * 8. Circle Icons
 * 9. Social Icons
 * 10. Account Icons
 * 11. Jackpot Icons
 * 12. Uncategorized Icons
 * ----------------------
 * CUSTOM GROUPED
 * 13. Sports Icons
 * 14. Casino Icons
 * 15. Vendor Icons
 * 16. Colored Icons
 * ----------------------
 * 17. Special Cases and exceptions
 * (Note: Consider splitting this file if it grows too large.)
 */

// 1. Base Path & Base Style
$icon-base-path: "../../public/img/icons";

.icon {
  width: 16px;
  height: 16px;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  flex-shrink: 0;
}

// 2. Icon Sizes
$icon-sizes: (
  "xx-small": (
    width: 8px,
    height: 8px,
  ),
  "x-small": (
    width: 16px,
    height: 16px,
  ),
  "small": (
    width: 20px,
    height: 20px,
  ),
  "medium": (
    width: 32px,
    height: 32px,
  ),
  "large": (
    width: 48px,
    height: 48px,
  ),
  "x-large": (
    width: 80px,
    height: 80px,
  ),
  "xx-large": (
    width: 120px,
    height: 120px,
  ),
);

@each $size-name, $size-dimensions in $icon-sizes {
  .icon.#{$size-name} {
    width: map-get($size-dimensions, width);
    height: map-get($size-dimensions, height);
  }
}

// 3. Color Variations
$colors: (
  "grey-1": var(--color-grey-1),
  "grey-5": var(--color-grey-5),
  "darkish-blue": var(--color-darkish-blue),
  "transparent": transparent,
  "transparent-white": var(--transparent-white),
  "primary": var(--color-primary),
  "primary-900": var(--color-primary-900),
  "secondary": var(--color-neutral-darker),
  "accent": var(--color-accent),
  "white": var(--color-white),
  "dark": var(--color-neutral-darker),
  "black": var(--color-black),
  "danger": var(--color-danger),
  "info": var(--color-info),
  "neutral-1": var(--color-neutral-1),
  "neutral-2": var(--color-neutral-2),
  "neutral-3": var(--color-neutral-3),
  "neutral-4": var(--color-neutral-4),
  "neutral-5": var(--color-neutral-5),
  "neutral-6": var(--color-neutral-6),
  "neutral-7": var(--color-neutral-7),
  "neutral-8": var(--color-neutral-8),
  "shuftipro": var(--color-shuftipro),
);

@each $color-name, $color-value in $colors {
  .icon.#{$color-name} {
    background-color: $color-value;
  }
}

// 4. Arrow Icons
$arrow-icons: (
  "arrow-down": "arrow-down",
  "arrow-up": "arrow-up",
  "arrow-right": "arrow-right",
  "arrow-left": "arrow-left",
  "play-arrow": "play-arrow",
);

@each $icon-class, $icon-file in $arrow-icons {
  .icon.#{$icon-class} {
    -webkit-mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
    mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
  }
}

// 5. Action Icons
$action-icons: (
  "delete": "delete",
  "edit": "edit",
  "close": "close",
  "circle-close": "circle-close",
  "check": "check",
  "circle-check": "circle-check",
  "search": "search",
  "fullscreen": "fullscreen",
);

@each $icon-class, $icon-file in $action-icons {
  .icon.#{$icon-class} {
    -webkit-mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
    mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
  }
}

// 6. Utility Icons
$utility-icons: (
  "history": "history",
  "circle-user": "circle-user",
  "timer": "timer",
  "timer-outline": "timer-outline",
  "filters": "filters",
);

@each $icon-class, $icon-file in $utility-icons {
  .icon.#{$icon-class} {
    -webkit-mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
    mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
  }
}

// 7. Navigation Icons
$navigation-icons: (
  "menu": "menu",
  "calendar-craciun": "calendar-craciun",
  "lightning": "lightning",
  "betting": "betting",
  "casino": "casino",
  "casino2": "casino2",
  "casino4": "casino4",
  "faq": "faq",
  "help": "help",
  "live-casino": "live-casino",
  "live": "live",
  "login": "login",
  "logout": "logout",
  "lottery": "lottery",
  "mybets": "mybets",
  "copy-clipboard": "copy-clipboard",
  "mybets2": "mybets2",
  "products": "products",
  "promo": "promo",
  "promo2": "promo2",
  "easter-calendar": "easter-calendar",
  "shops": "shops",
  "sports": "sports",
  "sports-live": "sports-live",
  "user": "user",
  "home": "home",
  "email": "email",
  "config": "config",
  "support": "support",
  "withdrawal": "withdrawal",
  "manage-search": "manage-search",
);

@each $icon-class, $icon-file in $navigation-icons {
  .icon.#{$icon-class} {
    -webkit-mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
    mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
  }
}

// 8. Circle Icons
$circle-icons: (
  "circle-arrow-up": "circle-arrow-up",
  "circle-arrow-down": "circle-arrow-down",
  "circle-info": "circle-info",
  "circle-right": "circle-right",
  "circle-play": "circle-play",
  "circle-add": "circle-add",
);

@each $icon-class, $icon-file in $circle-icons {
  .icon.#{$icon-class} {
    -webkit-mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
    mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
  }
}

// 9. Social Icons
$social-icons: (
  "youtube": "youtube",
  "twitter": "twitter",
  "instagram": "instagram",
  "facebook": "facebook",
  "share": "share",
);

@each $icon-class, $icon-file in $social-icons {
  .icon.#{$icon-class} {
    -webkit-mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
    mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
  }
}

// 10. Account Icons
$account-icons: (
  "list": "list",
  "front-hand": "front-hand",
  "star": "star",
  "myaccount": "myaccount",
  "mydocuments": "mydocuments",
  "shuftipro": "sp_black_logo",
  "idcheck": "idcheck",
  "balance": "balance",
  "bonus": "bonus",
  "receipt": "receipt",
);

@each $icon-class, $icon-file in $account-icons {
  .icon.#{$icon-class} {
    -webkit-mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
    mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
  }
}

// 11. Jackpot Icons
$jackpot-icons: (
  "jackpot-hearts": "jackpot-hearts",
  "jackpot-clubs": "jackpot-clubs",
  "jackpot-spades": "jackpot-spades",
  "jackpot-diamonds": "jackpot-diamonds",
  "jackpot-egypt": "jackpot-egypt",
  "jackpots": "jackpots",
);

@each $icon-class, $icon-file in $jackpot-icons {
  .icon.#{$icon-class} {
    -webkit-mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
    mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
  }
}

// 12. Uncategorized Icons
$uncategorized: (
  "video": "video",
  "popular": "popular",
  "new": "new",
  "desktop": "desktop",
  "flower": "flower",
  "heart": "heart",
  "heart-outline": "heart-outline",
  "meatball": "meatball",
  "mega": "mega",
  "netlify": "netlify",
  "wallet": "wallet",
  "windows": "windows",
  "upload": "upload",
  "download": "download",
  "progress": "progress",
  "location": "location",
  "show": "show",
  "hide": "hide",
  "double-check": "double-check",
  "expired": "expired",
  "exclamation-triangle": "exclamation-triangle",
  "exclamation-triangle-orange": "exclamation-triangle-orange",
  "cold": "cold",
  "hot": "hot",
  "paid": "paid",
  "circle-luckyseven": "circle-luckyseven",
);

@each $icon-class, $icon-file in $uncategorized {
  .icon.#{$icon-class} {
    -webkit-mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
    mask-image: url("#{$icon-base-path}/#{$icon-file}.svg");
  }
}

// 13. Sports Icons
$sports-icons: (
  "basket": "basket",
  "baseball": "baseball",
  "call-of-duty": "call-of-duty",
  "cs-go": "cs-go",
  "darts": "darts",
  "dota2": "dota2",
  "e-fighting": "e-fighting",
  "e-ice-hockey": "e-ice-hockey",
  "e-tennis": "e-tennis",
  "e-volleyball": "e-volleyball",
  "fifa": "fifa",
  "american-football": "american-football",
  "australian-football": "australian-football",
  "football": "football",
  "handball": "handball",
  "hockey": "hockey",
  "kabaddi": "kabaddi",
  "king-of-glory": "king-of-glory",
  "lol": "lol",
  "nba2k": "nba2k",
  "rugby-league": "rugby-league",
  "rugby-union": "rugby-union",
  "contact-sports": "contact-sports",
  "table-tennis": "table-tennis",
  "tennis": "tennis",
  "valorant": "valorant",
  "volleyball": "volleyball",
);

@each $icon-class, $icon-file in $sports-icons {
  .icon.#{$icon-class} {
    -webkit-mask-image: url("#{$icon-base-path}/sports/#{$icon-file}.svg");
    mask-image: url("#{$icon-base-path}/sports/#{$icon-file}.svg");
  }
}

// 14. Casino Icons
$casino-icons: (
  "conti": "conti",
  "casino-lobby": "casino-lobby",
  "selectie-conti": "selectie-conti",
  "cumpara-speciale": "cumpara-speciale",
  "blackjack": "blackjack",
  "dropswins": "drops&wins",
  "drops": "drops&wins",
  "egiptene": "egiptene",
  "egypt-quest": "egypt-quest",
  "favorites": "favorites",
  "fructe": "fructe",
  "group-search": "group-search",
  "jackpot": "jackpot-games",
  "roulette": "roulette",
  "ruleta": "roulette",
  "jocuri-noi": "jocuri-noi",
  "blackjack-si-ruleta": "jocuri-noi",
  "carti": "carti",
  "jocuri-de-carti": "carti",
  "baccarat": "baccarat",
  "megaways": "megaways",
  "multilines": "multilines",
  "toate": "toate-jocurile",
  "populare": "populare",
  "mondial": "mondial",
  "craciun": "craciun",
  "recent-games": "recent-games",
  "party": "party",
  "flori": "party",
  "primavera": "party",
  "summer": "summer",
  "selectate": "selectate",
  "egipt": "egipt",
  "septari-si-fructe": "septari-si-fructe",
  "jocuri-din-sali": "jocuri-din-sali",
  "isoftbet": "isoftbet",
  "hacksaw": "hacksaw",
  "nolimit": "nolimit",
  "ezugi": "ezugi",
  "evolution": "evolution",
);

@each $icon-class, $icon-name in $casino-icons {
  .icon.#{$icon-class} {
    -webkit-mask-image: url("#{$icon-base-path}/casino/#{$icon-name}.svg");
    mask-image: url("#{$icon-base-path}/casino/#{$icon-name}.svg");
  }
}

// 15. Vendor Icons
// These icons come with predetermined colors and aren't customizable
$vendors-icons: (
  "default": "default",
  "amatic": "amatic",
  "amusnet": "amusnet",
  "betixon": "betixon",
  "bgt": "bgt",
  "bragg": "bragg",
  "ct_gaming": "ct_gaming",
  "egtdigital": "egtdigital",
  "evolution-gaming": "evolution-gaming",
  "evoplay": "evoplay",
  "games-global": "games-global",
  "gamevy": "gamevy",
  "gaming-corps": "gaming-corps",
  "gamomat": "gamomat",
  "greentube": "greentube",
  "hacksaw": "hacksaw",
  "isoftbet": "isoftbet",
  "kajot": "kajot",
  "kalamba": "kalamba",
  "netent": "netent",
  "nolimit-city": "nolimit-city",
  "novomatic": "novomatic",
  "playn-go": "playn-go",
  "playson": "playson",
  "playtech": "playtech",
  "pragmatic-play": "pragmatic-play",
  "quickspin": "quickspin",
  "real-dealer": "real-dealer",
  "redrake": "redrake",
  "relax-gaming": "relax-gaming",
  "sg": "sg",
  "skywind": "skywind",
  "slotmatrix": "slotmatrix",
  "smartsoft": "smartsoft",
  "spinomenal": "spinomenal",
  "spribe": "spribe",
  "synot": "synot",
  "thunderkick": "thunderkick",
  "wazdan": "wazdan",
);

@each $vendor-class, $vendor-file in $vendors-icons {
  .icon.#{$vendor-class} {
    background-image: url("../../public/img/vendors/#{$vendor-file}-logo-small.svg");
  }
}

// 16. Colored Icons
// These icons come with predetermined colors and aren't customizable
$colored-icons: (
  "circle-denied": "circle-denied",
  "cards": "cards",
  "square-won": "square-won",
  "square-lost": "square-lost",
  "square-draw": "square-draw",
  "square-open": "square-open",
);

@each $icon-class, $icon-file in $colored-icons {
  .icon.#{$icon-class} {
    background-image: url("#{$icon-base-path}/#{$icon-file}.svg");
  }
}

// 17. Special Cases and exceptions
.icon.drops-\&-wins {
  -webkit-mask-image: url("../../public/img/icons/casino/drops&wins.svg");
  mask-image: url("../../public/img/icons/casino/drops&wins.svg");
}
