$number-count: 20;
$animation-duration: 8s;
.mm-game {
  position: relative;
  &__bg {
    width: 100%;
  }
  &__step {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 4px solid white;
    background-color: black;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    &:before {
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      width: 100%;
      position: absolute;
      line-height: 50px;
    }

    @for $i from 1 through $number-count {
      &:nth-child(#{$i}) {
        animation: zoom $animation-duration ease-in-out infinite;
        animation-delay: ($i - 1) * $animation-duration / $number-count;
      }
    }
    @for $i from 1 through $number-count {
      &:nth-child(#{$i})::before {
        content: "#{$i}";
      }
    }

    &--locked {
      filter: grayscale(100%);

      &:after {
        content: "";
        display: block;
        background-image: url("/img/games/lock.svg");
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        width: 18px;
        height: 24px;
        position: absolute;
        top: -10px;
        right: -5px;
      }
    }
    &--1 {
      top: auto;
      bottom: 15%;
      left: 12%;
    }
    &--2 {
      top: auto;
      bottom: 25%;
      left: 52%;
    }
    &--3 {
      top: auto;
      bottom: 38%;
      left: 5%;
    }
    &--4 {
      top: auto;
      bottom: 45%;
      left: 30%;
    }
    &--5 {
      top: auto;
      bottom: 52%;
      left: 60%;
    }
    &--6 {
      top: auto;
      bottom: 67%;
      left: 52%;
    }
  }
  &__step-pin {
    margin-top: 60px;
    display: block;
    margin-inline: auto;
  }
}
.games-page {
  .page-content {
    padding: 0 !important;
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.2);
  }
  10% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
