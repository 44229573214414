.jackpot-wrapper {
  display: flex;
  flex-direction: column;
  padding: var(--space-md);
  background-color: var(--color-foreground);
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: -5px -5px 10px rgba(35, 38, 41, 0.7),
    5px 5px 10px rgba(0, 0, 0, 0.8);

  @include desktop {
    padding: var(--space-xl) var(--space-2xl);
    background-position: -50px center, calc(100% + 50px) center;
    background-size: unset;
  }

  .jackpot-header {
    @include desktop {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: var(--space-xl);
    }
  }

  .jackpot-title {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: var(--space-md);
    border-radius: var(--radius-xl);
    text-align: center;
  }
}

.jackpot-categories {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-md);
  flex: 1;
  @include desktop {
    gap: var(--space-lg);
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.jackpot-counter {
  display: flex;
  flex-direction: row;
  gap: var(--space-md);
  cursor: pointer;

  background: var(--color-neutral-2);
  border: 1px solid transparent;
  border-radius: var(--radius-md);
  padding: var(--space-sm) var(--space-md);

  @media screen and (max-width: 400px) {
    gap: var(--space-sm);
    padding: var(--space-sm);
  }

  &.active {
    background: var(--color-primary);
    border: 1px solid var(--color-primary);
    &::before,
    &::after {
      color: var(--color-primary-900);
    }

    .counter {
      display: flex;
      color: var(--text-color);
      &::after {
        color: var(--text-color);
      }
    }
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .counter {
    display: flex;
    gap: var(--space-sm);
    flex: 1;
    align-items: center;
    justify-content: space-between;
    color: var(--color-black);
    font-size: 16px;

    @media screen and (max-width: 400px) {
      letter-spacing: -1px;
    }

    &::before {
      font-variant-numeric: tabular-nums;
      color: var(--color-neutral-3);
    }

    &::after {
      content: attr(data-suffix);
      letter-spacing: normal;
      color: var(--color-neutral-4);
      font-weight: 800;
      line-height: 1;
      @media screen and (max-width: 400px) {
        letter-spacing: -1px;
      }
    }
  }
}

.jackpot-details {
  display: grid;
  gap: var(--gap);
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "winners winners"
    "biggest recent";

  width: 100%;
  margin-top: var(--gap);
  .item {
    overflow: hidden;

    &:nth-child(1) {
      grid-area: winners;
    }
    &:nth-child(2) {
      grid-area: biggest;
    }
    &:nth-child(3) {
      grid-area: recent;
    }

    &:nth-child(1) {
      display: flex;
      gap: var(--space-sm);
      align-items: center;
      justify-content: center;
      border-radius: var(--radius-md);
      background-color: var(--jackpot-details-bg-first);
      padding: var(--space-sm);

      font-size: 16px;
      font-weight: 900;
    }

    &:nth-child(2),
    &:nth-child(3) {
      display: flex;
      flex-direction: column;
      background: var(--jackpot-details-bg-second);
      border-radius: var(--radius-md);
    }
  }

  .title {
    color: var(--color-white);
    background: var(--transparent-darker);
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    padding: var(--space-sm);
  }

  .number {
    text-align: center;
    padding: var(--space-sm);

    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-primary);

    span {
      font-weight: 600;
      font-size: 12px;
      color: var(--color-neutral-5);
    }
  }

  .details {
    padding: var(--space-sm);
    font-weight: 600;
    font-size: 12px;
    color: var(--text-color-faded);
    text-align: center;
  }
}

[data-theme="dark"] {
  // background-color: var(--color-neutral-dark);
  .jackpot-title,
  .jackpot-details {
    color: var(--color-white);
  }

  .jackpot-details {
    .title,
    .details {
      // color: var(--color-neutral-3);
    }
  }
}
