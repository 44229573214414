.custom-404 {
  .page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: var(--space-2xl);
    text-align: center;
  }
  .banner-404 {
    max-width: 234px;
  }
  .title {
    font-size: var(--text-lg);
    font-weight: 700;
    margin-top: var(--space-4xl);
    margin-bottom: var(--space-md);
  }
  .subtitle {
    font-size: var(--text-md);
    font-weight: 600;
    margin-bottom: var(--space-2xl);
    color: var(--color-grey-1);
  }
  .btn-large {
    text-transform: unset;
    padding: var(--space-lg) var(--space-3xl);
    height: unset;
    @include mobile {
      width: 100%;
    }
  }
}
