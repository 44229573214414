
.alert-wrapper {
    display: flex;
    gap: var(--space-md);
    line-height: 1.2;
    // align-items: center;
    // width: 80%;
    max-width: 100%;
    margin: 5px auto;

    // color: var(--tex);
    font-weight: 500;
    font-size: var(--text-sm);

    padding: var(--space-md);
    // background: var(--color-neutral-2);
    // border: 1px solid var(--color-neutral-3);
    border-radius: var(--radius-md);


    .icon {
        // margin: 2px 0;
        align-items: center;
    }

    span {
        opacity: 0.9;
    }

    a {
        color: var(--text-color);
    }

    &.note {
        padding: 0;
        color: var(--color-neutral-5);
    }

    &.warning {
        background: var(--color-accent-light);
        border: 1px solid var(--color-accent-dark);
        .icon {
            background: var(--color-accent-dark);
        }
    }

    &.info {
        background: var(--color-info-light);
        border: 1px solid var(--color-info);
        .icon {
            background: var(--color-info-dark);
        }        
    }

    &.error {
        background: var(--color-danger-light);
        border: 1px solid var(--color-danger);
        .icon {
            background: var(--color-danger-dark);
        }        
    }

    &.success {
        background: var(--color-primary-light);
        border: 1px solid var(--color-primary);
        .icon {
            background: var(--color-primary);
        }        
    }


    a {
        color: var(--color-primary);
        font-size: inherit;
    }
}

[data-theme="dark"] {
    .alert-wrapper {    
        &.note {
            color: var(--color-neutral-2);
        }
        &.warning {
            background: var(--color-accent-dark);
            border: 1px solid transparent;
            .icon {
                background: var(--color-accent-light);
            }
        }
    
        &.info {
            background: var(--color-info-dark);
            border: 1px solid transparent;
            .icon {
                background: var(--color-info-light);
            }        
        }
    
        &.error {
            background: var(--color-danger-dark);
            border: 1px solid transparent;
            .icon {
                background: var(--color-danger-light);
            }        
        }
    
        &.success {
            background: var(--color-primary);
            border: 1px solid transparent;
            .icon {
                background: var(--color-primary-light);
            }        
        }
    }
}

.main-content {
    .note {
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        text-align: left;
    }
}