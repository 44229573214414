.casino-page {
  height: 100%;

  .casino-search-input-wrapper {
    position: sticky;
    top: var(--header-height);
    margin-bottom: var(--gap);
    padding: 4px;
    background: var(--color-background);
    z-index: 5;
  }

  .jackpot-wrapper {
    margin-bottom: var(--gap);
  }

  .available_games {
    padding: var(--space-md) 0;
  }

  .game-category,
  .vendors-widget {
    width: calc(100% - 2 * var(--space-md));
    margin: 0 auto var(--space-2xl);
    .category-header {
      display: flex;
      align-items: center;
      font-weight: bold;
      padding: var(--space-sm) 0 var(--space-md);
      gap: var(--space-md);
    }
    .category-title {
      font-size: var(--text-md);
      font-weight: 700;
      line-height: 1;
      color: var(--color-grey-1);
    }
  }

  .search_filter_btn {
    margin-top: var(--space-lg);
  }
}

.search_filter_btn {
  display: flex;
  align-items: center;
  gap: var(--space-lg);

  background: var(--color-neutral-darker);
  padding: var(--space-md) var(--space-lg);
  border-radius: var(--radius-md);
  cursor: pointer;

  .label {
    color: var(--color-white);
    font-size: var(--text-md);
    font-weight: bold;
    margin-right: auto;
  }
}

.search_and_filters {
  display: flex;
  gap: var(--space-md);
  margin: var(--space-lg) 0 var(--space-md) 0;
  .square-filter-btn {
    height: auto;
  }
}

.square-search {
  display: flex;
  align-items: center;
  gap: var(--space-md);
  width: 100%;
  background: var(--color-neutral-darker);
  border-radius: var(--radius-md);
  padding: var(--space-md);

  .close {
    margin-right: var(--space-sm);
    cursor: pointer;
  }
}

.input-search {
  width: 100%;

  background: var(--color-neutral-darker);
  border-radius: var(--radius-md);
  color: var(--color-white);
  font-size: var(--text-md);

  &:focus {
    outline-width: 0;
  }
  &::placeholder {
    color: var(--color-white);
  }
}

.filters_wrapper {
  background: var(--color-primary);
  color: var(--color-white);
  margin: 0 0 var(--space-md) 0;
  padding: var(--space-xl) var(--space-xl) var(--space-sm);
  border-radius: var(--radius-md);
}
.filter_group {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  align-items: flex-start;

  .filter_group_header {
    display: flex;
    padding-bottom: var(--space-sm);
    margin-bottom: var(--space-md);
    border-bottom: 1px solid var(--border-color);
    width: 100%;
    cursor: pointer;
    font-weight: 500;
  }

  .filter_group_name {
    margin-right: auto;
    font-size: var(--text-md);
  }

  .filter_group_items {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);
    &.open {
      max-height: 500px;
      transition: max-height 0.25s ease-in;
      margin-bottom: var(--space-xl);
    }
  }
}

.homepage-games,
.category-games,
.category-vendors,
.vendor-games {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: var(--space-md);
  overflow: visible;
  border-radius: var(--radius-md);
  @include tablet {
    grid-template-columns: 1fr 1fr 1fr;
    overflow: hidden;
  }
  @include mobile {
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
  }
}

.category-vendors {
  @include tablet {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.category-more-games {
  display: block;
  margin: var(--space-2xl) auto 0;
}

.game-wrapper {
  position: relative;

  .game-hover {
    position: absolute;
    inset: 0;
    z-index: 3;
    background: var(--transparent-black);
    min-height: 100px;
    aspect-ratio: 14/9;
    // TODO: the aspect ration can be set to a local variable
    cursor: pointer;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.2s all ease;

      &:hover {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }

    .game-buttons-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: var(--space-md);

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .btn-action-game,
      .btn-secondary-game {
        width: 100px;
        padding: var(--space-sm);
        border-radius: var(--radius-md);
        font-size: var(--text-xs);
        font-weight: 600;
        text-transform: uppercase;
      }

      .btn-action-game {
        background: var(--color-accent);
        color: var(--color-black);
      }

      .btn-secondary-game {
        background: var(--color-primary);
        color: var(--color-white);
      }
    }
  }

  .game-info {
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: var(--space-sm);
    align-items: center;
    padding-top: var(--space-sm);
    width: 100%;
    .game-info-btn {
      padding: var(--space-xs);
      cursor: pointer;
      &:hover {
        border-color: var(--color-secondary);
        .icon {
          background-color: var(--color-secondary);
        }
      }
    }
  }

  .game-name {
    font-size: var(--text-sm);
    font-weight: 600;
    cursor: pointer;
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .game-company {
    display: flex;
    gap: var(--space-sm);
    align-items: center;
    color: var(--color-neutral-4);
    margin-top: var(--space-sm);
    font-size: var(--text-xs);
    font-weight: 600;
    cursor: pointer;
  }

  .game-box {
    width: 100%;
    min-height: 70px;
    aspect-ratio: 14 / 9;
    position: relative;
    overflow: hidden;
    border-radius: var(--radius-md);
    cursor: pointer;
    img {
      transition: transform 0.4s ease;
    }
  }

  &.hovered {
    & > .game-box img {
      transform: scale(1.05);
    }
  }

  .game-badges {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 8px;
    left: 8px;
    gap: 5px;
    z-index: 2;
  }
  .game-favorite {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;

    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 4;

    border-radius: var(--radius-sm);
    background: var(--transparent-black);
    cursor: pointer;
    .favorites-icon {
      transition: all 0.2s;
    }
  }
}

.empty-card {
  width: 100%;
  min-height: 100px;
  aspect-ratio: 14 / 9;
  position: relative;
  overflow: hidden;
  background: var(--color-neutral-1);
  margin-bottom: 43px; // simulate name and company name height TODO: placeholders
  @media screen and (min-width: 800px) {
    border-radius: var(--radius-md);
  }
}

.applied_filters {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--space-sm);
  .filter {
    display: flex;
    align-items: center;
    line-height: 1;
    &.pill {
      padding: 0 0 0 10px;
    }
    & > .close {
      padding: 5px 7px 5px 5px;
      cursor: pointer;
    }
  }
  .clear_all {
    background: var(--color-neutral-5);
  }
  .vendor-items {
    background: var(--color-primary);
  }
  .group-items {
    background: var(--color-accent);
  }
}

.pill {
  padding: 5px 10px 3px;
  font-size: var(--text-2xs);
  font-weight: bold;
  line-height: 1;
  letter-spacing: 1px;
  border-radius: var(--radius-lg);
  text-transform: uppercase;

  background: var(--color-primary);
  color: var(--color-white);

  &.accent {
    background: var(--color-accent);
    color: var(--color-black);
  }
}

#casinoJackpots {
  iframe {
    width: 100%;
    border: none;
  }
}

// [data-theme='dark'] {
//   .game-wrapper {
//     color: var(--color-white);
//   }
//   .game-name {
//     color: var(--color-white);
//   }
//   .category-title {
//     color: var(--color-grey-1);
//   }
//   .game-company {
//     color: var(--color-neutral-3);
//   }
//   .empty-card {
//     background: var(--color-neutral-8);
//   }
// }
