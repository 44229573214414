:root {
  --gradient-faded-grey-8: linear-gradient(
    180deg,
    var(--color-grey-8) 0%,
    rgba(41, 50, 65, 0) 100%
  );

  --gradient-main: linear-gradient(150deg, #b27b20, #e9d694);

  --gradient-rectangle-primary-light: linear-gradient(
    125deg,
    rgba(48, 206, 150, 0.12) 20%,
    rgba(48, 206, 150, 0) 20%,
    rgba(48, 206, 150, 0) 80%,
    rgba(48, 206, 150, 0.12) 80%
  );

  --gradient-rectangle-primary-light-hover: linear-gradient(
    125deg,
    rgba(48, 206, 150, 0.12) 15%,
    rgba(48, 206, 150, 0) 15%,
    rgba(48, 206, 150, 0) 85%,
    rgba(48, 206, 150, 0.12) 85%
  );

  --gradient-rectangle-primary-900: linear-gradient(
    125deg,
    rgba(13, 107, 37, 0.1) 20%,
    rgba(13, 107, 37, 0) 20%,
    rgba(13, 107, 37, 0) 80%,
    rgba(13, 107, 37, 0.1) 80%
  );

  --gradient-rectangle-primary-900-hover: linear-gradient(
    125deg,
    rgba(13, 107, 37, 0.1) 15%,
    rgba(13, 107, 37, 0) 15%,
    rgba(13, 107, 37, 0) 85%,
    rgba(13, 107, 37, 0.1) 85%
  );

  --submenu-radial-gradient: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.15) 10%,
    rgba(255, 255, 255, 0) 70%
  );
  --slider-gradient: linear-gradient(
    180deg,
    rgba(50, 50, 50, 0) 40%,
    rgba(50, 50, 50, 0.2) 60%,
    rgba(50, 50, 50, 0.6) 80%
  );

  --primary-gradient: linear-gradient(180deg, #189938, #0b551d);

  --primary-900-gradient: linear-gradient(180deg, #147b2e, #09611f);

  --primary-transparent-gradient: linear-gradient(
    90deg,
    rgba(11, 85, 29, 0) 60%,
    rgba(11, 85, 29, 0.6) 75%,
    rgba(11, 85, 29, 1) 100%
  );

  --transparent-black-gradient: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );

  --transparent-black-gradient-v: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );

  // lottery card glossy effect
  --gradient-rectangle: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0) 15%,
    rgba(255, 255, 255, 0.05) 15%,
    rgba(255, 255, 255, 0.05) 85%,
    rgba(255, 255, 255, 0) 85%
  );

  &[data-theme="dark"] {
    --gradient-rectangle-primary-light: linear-gradient(
      125deg,
      rgba(255, 255, 255, 0.04) 20%,
      rgba(13, 107, 37, 0) 20%,
      rgba(13, 107, 37, 0) 80%,
      rgba(255, 255, 255, 0.04) 80%
    );

    --gradient-rectangle-primary-light-hover: linear-gradient(
      125deg,
      rgba(255, 255, 255, 0.02) 15%,
      rgba(13, 107, 37, 0) 15%,
      rgba(13, 107, 37, 0) 85%,
      rgba(255, 255, 255, 0.02) 85%
    );
  }

  --transition-bg: linear-gradient(
    180deg,
    var(--color-white),
    var(--color-neutral-lighter)
  );

  &[data-theme="dark"] {
    --transition-bg: linear-gradient(
      180deg,
      var(--color-neutral-dark),
      var(--color-neutral-darkest)
    );
  }
}
