/* common deposit & withdraw styles */
.deposit-wrapper,
.withdraw-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: var(--max-input-width);
  .notice {
    display: flex;
    flex-direction: row;
    gap: var(--space-md);
    margin: 0 0 var(--space-2xl) 0;
  }

  .deposit-btn,
  .withdraw-btn {
    width: 100%;
    max-width: var(--max-input-width);
    margin-top: var(--space-lg);
    align-self: center;
  }
  .feedback-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    margin-top: var(--gap);
  }
}

.deposit-amount,
.withdraw-amount {
  display: flex;
  flex-direction: column;
  gap: var(--space-lg);
  max-width: var(--max-input-width);
  margin: 0 auto;
  width: 100%;

  form {
    margin: 0 auto;
  }

  .set-amount {
    display: flex;
    gap: var(--space-lg);

    label {
      margin-top: var(--space-md);
    }
  }
  .btn-large {
    flex: 1;
  }
}

.deposit-wrapper {
  .bonus-wrapper {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }
  .bonus-code-wrapper {
    width: 100%;
  }
  .input-label {
    margin: var(--space-xl) 0 var(--space-md);
  }
}

.pending-withdraw-wrapper {
  margin: var(--space-2xl) 0;
  .pending-item {
    display: flex;
    &:not(:first-child) {
      margin-top: var(--space-xl);
    }

    & > .icon {
      margin-right: var(--space-xl);
    }

    .description,
    .date {
      flex: 1;
    }

    .description,
    .date,
    .action {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid var(--color-neutral-2);
      padding-bottom: var(--space-md);
    }

    &:last-child {
      .description,
      .date,
      .action {
        border-bottom: unset;
      }
    }
    .description {
      color: var(--color-neutral-6);
    }
    .action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: var(--space-md);
      align-items: center;
      margin: 0 auto;
      flex: 1;
    }
    .date {
      text-align: right;
      margin-left: auto;
      font-size: var(--text-sm);
    }
  }
}
